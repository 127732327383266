import { firebase } from "../../../utils/alias";
import { dispatchOnValueChange } from "./firebaseEvents";

const matchesRef = () => firebase.database().ref(`matches`);

const getMatchesResponse = matches => {
    return { type: "GET_MATCHES_RESPONSE", matches };
};

const listenOnMatchesChange = function*() {
    const ref = matchesRef();
    yield dispatchOnValueChange(ref, getMatchesResponse, {
        keepOnLogout: true
    });
};

export { matchesRef, listenOnMatchesChange };
