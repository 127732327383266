export const updates = (state = {}, action) => {
    switch (action.type) {
        case "UPDATE_STARTED":
            return {
                ...state,
                [action.id]: true
            };
        case "UPDATE_COMPLETE":
            const { [action.id]: _, ...rest } = state;
            return rest;
        default:
            return state;
    }
};

export const hasPendingChanges = state => {
    return Object.keys(state.updates).some(k => k);
};
