import React, { useState } from "react";
import { SmallButton } from "../../components/appUtilities/alerts/styles";
import { firebase } from "../../utils/alias";
import { isTest } from "../../store/sagas/initialiseFirebase";

export const RequestVerification = () => {
    const [requested, setRequested] = useState(false);
    if (!requested) {
        return (
            <SmallButton
                onClick={() => {
                    if (!isTest) {
                        firebase.auth().currentUser.sendEmailVerification();
                    }
                    setRequested(true);
                }}
            >
                Resend verification email
            </SmallButton>
        );
    }
    return "Sent!";
};
