import React from "react";
import { connect } from "react-redux";
import { Layout } from "../layout/Layout";
import { Error, FieldDiv } from "../FieldDiv";
import { formErrorMessage } from "../../store/reducers";
import { BigButton, Input } from "../styles/styles";
import { Form } from "../Form";

const _ForgottenPasswordForm = ({
    formErrorMessage,
    resetPassword,
    submit
}) => (
    <Layout bigHeader={true}>
        <Form
            onSubmit={e => {
                e.preventDefault();
                submit();
                resetPassword(e.target.email.value);
            }}
        >
            <FieldDiv>
                <Input type="email" placeholder="Email" name="email" />
            </FieldDiv>
            {formErrorMessage && (
                <FieldDiv>
                    <Error>{formErrorMessage}</Error>
                </FieldDiv>
            )}
            <FieldDiv>
                <BigButton>Reset password</BigButton>
            </FieldDiv>
        </Form>
    </Layout>
);

const dispatchActions = {
    resetPassword: email => ({
        type: "RESET_PASSWORD",
        email
    })
};

const mapState = state => ({
    formErrorMessage: formErrorMessage(state)
});

const ForgottenPasswordForm = connect(
    mapState,
    dispatchActions
)(_ForgottenPasswordForm);

export { ForgottenPasswordForm };
