import React from "react";
import { Layout } from "../components/layout/Layout";
import { Achievements } from "../components/profile/Acheivements";

let Profile = ({ match }) => {
    const {
        params: { displayName }
    } = match;
    return (
        <Layout>
            <h2>{displayName}</h2>
            <p>
                Profile Under Construction{" "}
                <span role="img" aria-label="Under construction">
                    🚧
                </span>
            </p>
            <Achievements {...{ displayName }} />
        </Layout>
    );
};

export { Profile };
