import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button as MuiButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { PlayArrow } from "@material-ui/icons";
import styled from "styled-components";

const Button = styled(MuiButton)`
    && {
        margin-right: 0.75rem;
        &:hover {
            color: white;
        }
        @media only screen and (max-width: 800px) {
            margin-right: 4px;
        }
    }
`;

export const ShootoutLink = () => {
    const isMobile = useMediaQuery("(max-width:370px)");
    return (
        <Button
            color="primary"
            component={Link}
            to="/shootout"
            variant="contained"
            size="small"
        >
            <PlayArrow style={{ marginRight: "0.5rem" }} />{" "}
            {isMobile ? "play" : "Play now"}
        </Button>
    );
};
