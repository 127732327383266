import React, { Component } from "react";
import { connect } from "react-redux";
import {
    allLoaded,
    isAdmin,
    isLoggedIn,
    permissionsLoaded
} from "../../store/reducers/index";
import { history } from "../../store/history";
import { LoadingPage } from "../../pages/LoadingPage";

const mapState = state => ({
    isLoggedIn: isLoggedIn(state),
    isAdmin: isAdmin(state),
    permissionsLoaded: permissionsLoaded(state),
    allLoaded: allLoaded(state)
});

const connectRedirectGuard = connect(mapState);

const adminOnly = ProtectedComponent => {
    class RedirectGuard extends Component {
        redirectIfNoPermission() {
            if (this.props.permissionsLoaded && !this.props.isAdmin) {
                history.replace("/");
            }
        }

        componentDidMount() {
            this.redirectIfNoPermission();
        }

        componentDidUpdate() {
            this.redirectIfNoPermission();
        }

        render() {
            if (this.props.allLoaded && this.props.isAdmin) {
                return <ProtectedComponent {...this.props} />;
            }
            return <LoadingPage />;
        }
    }
    return connectRedirectGuard(RedirectGuard);
};

export { adminOnly };
