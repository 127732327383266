import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { availableLeaderboards } from "../../../store/reducers/leaderboard";
import { colours } from "../../predictions/styles/colours";
import { history } from "../../../store/history";
import { ShareBoardInvite } from "./ShareBoardInvite";

const Root = styled.div`
    margin-left: 24px;
    margin-bottom: 12px;
    text-align: left;
    display: flex;
    align-items: center;
`;

const Select = styled.select`
    cursor: pointer;
    background: none;
    border: none;
    color: ${colours.greys[9]};
    outline-color: ${colours.greys[3]};
    padding: 8px;
    box-sizing: content-box;
    border: solid 1px ${colours.greys[2]};
    border-radius: 4px;
    &:hover {
        border: solid 1px ${colours.greys[4]};
    }
`;

const LinkMessage = styled.div`
    margin-top: 12px;
`;

export const SelectLeaderboard = ({
    selectedLeaderboard,
    setSelectedLeaderboard
}) => {
    const leaderboards = useSelector(availableLeaderboards);

    return (
        <Root>
            <Select
                value={selectedLeaderboard}
                onChange={e => {
                    const value = e.target.value;
                    if (value === "create") {
                        return history.push("/create-leaderboard");
                    }
                    setSelectedLeaderboard(value);
                }}
            >
                <option value="1">Everyone</option>
                {leaderboards.map(({ id, name }) => (
                    <option key={id} value={id}>
                        {name}
                    </option>
                ))}
                <option value="create">+ Create leaderboard</option>
            </Select>
            {selectedLeaderboard &&
                selectedLeaderboard !== "1" && (
                    <ShareBoardInvite leaderboardId={selectedLeaderboard} />
                )}
        </Root>
    );
};
