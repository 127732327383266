import React, { Fragment } from "react";
import {
    SmallButton,
    Left,
    Right,
    PaginateGrid,
    Middle,
    ShowOnDeskTop,
    ShowOnMobile
} from "./styles";

const ForwardButtons = ({ forward, fastForward }) => (
    <Right>
        <SmallButton onClick={forward} title="Go forwards 1 match day">
            {">"}
        </SmallButton>
        <SmallButton onClick={fastForward} title="Go forwards 5 match days">
            {">>"}
        </SmallButton>
    </Right>
);

const BackButtons = ({ back, fastBack }) => (
    <Left>
        <SmallButton onClick={fastBack} title="Go backwards 5 match days">
            {"<<"}
        </SmallButton>
        <SmallButton onClick={back} title="Go backwards 1 match day">
            {"<"}
        </SmallButton>
    </Left>
);

const PaginationButtonsSection = ({ changeMatchDayBy, children }) => (
    <Fragment>
        <PaginateGrid>
            <BackButtons
                back={changeMatchDayBy(-1)}
                fastBack={changeMatchDayBy(-5)}
            />
            <Middle>
                <ShowOnDeskTop data-hj-whitelist>{children}</ShowOnDeskTop>
            </Middle>
            <ForwardButtons
                forward={changeMatchDayBy(1)}
                fastForward={changeMatchDayBy(5)}
            />
        </PaginateGrid>
        <ShowOnMobile data-hj-whitelist>{children}</ShowOnMobile>
    </Fragment>
);

export { PaginationButtonsSection };
