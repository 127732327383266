import React from "react";
import { Layout } from "../components/layout/Layout";
import { NotificationsSection } from "../components/account/NotificationsSection";
import { UserDetailsSection } from "../components/account/UserDetailsSection";
import { colours } from "../components/predictions/styles/colours";
import styled from "styled-components";

const Emphasis = styled.span`
    color: ${colours.greys[7]};
`;

const Account = () => (
    <Layout
        contentWrapperStyle={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}
    >
        <UserDetailsSection />
        <NotificationsSection />

        <div style={{ color: colours.greys[6], marginTop: 80, maxWidth: 400 }}>
            To give <Emphasis>feedback</Emphasis>, ask for{" "}
            <Emphasis>help</Emphasis>, or <Emphasis>delete</Emphasis> your
            account, please email{" "}
            <a
                href="mailto:joe@predictable.xyz"
                style={{ color: colours.primary[8] }}
            >
                joe@predictable.xyz
            </a>{" "}
        </div>
    </Layout>
);

export { Account };
