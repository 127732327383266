import React, { useRef, useState } from "react";
import styled from "styled-components";
import { motion, useMotionValue } from "framer-motion";
import { colours } from "../predictions/styles/colours";

const Root = styled.div`
    background-color: ${colours.greys[2]};
    width: 100%;
    max-width: 1000px;
    min-width: min(1000px, 100%);
    height: 50vh;
`;

const MotionRoot = styled(motion.div).attrs({})`
    min-width: min(1000px, 100%);
    box-sizing: border-box;
`;

const Pitch = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colours.greys[2]};
    width: 100%;
    height: 50vh;
    min-height: 350px;
    position: relative;
`;

const DrawArea = styled.div`
    width: 100%;
    height: 25vh;
    text-align: center;
    color: ${colours.greys[6]};
    padding-top: 32px;
`;

const Ball = styled(motion.div).attrs({})`
    background-color: white;
    border: solid 3px ${colours.greys[2]};
    width: 100px;
    height: 100px;
    border-radius: 50px;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 10vmin / 2);
    left: calc(50% - 10vmin / 2);
`;

const Goal = styled.div`
    background-color: white;
    border: solid 3px ${colours.greys[2]};
    width: 15%;
    height: 25vmin;
    min-height: 150px;
    min-width: 90px;
`;

const isInside = (el1, el2, left) => {
    var rect1 = el1.getBoundingClientRect();
    var rect2 = el2.getBoundingClientRect();
    return (
        rect1.top >= rect2.top &&
        (!left || rect1.right <= rect2.right) &&
        rect1.bottom <= rect2.bottom &&
        (left || rect1.left >= rect2.left)
    );
};

export const Shootout = ({ onPredictionSubmit, isSubmitting }) => {
    const rootRef = useRef(null);
    const awayGoalRef = useRef(null);
    const homeGoalRef = useRef(null);
    const drawAreaRef = useRef(null);
    const ballRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    return (
        <Root>
            <MotionRoot innerRef={rootRef}>
                <Pitch>
                    <Goal innerRef={homeGoalRef} />
                    {!isSubmitting && (
                        <Ball
                            drag
                            dragConstraints={rootRef}
                            onUpdate={e => {
                                if (isSubmitting) {
                                    return;
                                }
                                const isInHomeGoal = isInside(
                                    ballRef.current,
                                    homeGoalRef.current,
                                    true
                                );
                                const isInAwayGoal = isInside(
                                    ballRef.current,
                                    awayGoalRef.current,
                                    false
                                );
                                const isInDrawArea = isInside(
                                    ballRef.current,
                                    drawAreaRef.current
                                );
                                if (!isDragging) {
                                    if (isInAwayGoal) {
                                        onPredictionSubmit("home")();
                                    } else if (isInHomeGoal) {
                                        onPredictionSubmit("away")();
                                    } else if (isInDrawArea) {
                                        onPredictionSubmit("draw")();
                                    }
                                }
                            }}
                            onDragStart={() => setIsDragging(true)}
                            onDragEnd={() => setIsDragging(false)}
                            dragTransition={{ power: 2, bounceStiffness: 400 }}
                            innerRef={ballRef}
                            whileHover={{ scale: 1.05 }}
                        />
                    )}
                    <Goal innerRef={awayGoalRef} />
                </Pitch>
                <DrawArea innerRef={drawAreaRef}>Draw</DrawArea>
            </MotionRoot>
        </Root>
    );
};
