import React, { Component } from "react";
import { Bottom, Input, Top } from "../styles/inputs";
import { connect } from "react-redux";
import { loGet } from "../../../utils/alias";
import { hasFinished } from "../../../utils/football-data-org/statuses";
import { isLoggedIn } from "../../../store/reducers";

const setChecked = inputId => {
    document.getElementById(inputId).checked = true;
};

const createSubmitFor = inputId => () => {
    setChecked(inputId);
    document.getElementById("predictionsFormSubmit").click();
};

const defaultSubheadings = {
    home: "win",
    draw: "draw",
    away: "win"
};

class PillSection extends Component {
    componentDidUpdate = prev => {
        if (this.props.isSelected && !prev.isSelected) {
            setChecked(this.props.inputId);
        }
    };

    render() {
        const {
            disabled,
            inputId,
            isCorrect,
            isSelected,
            frequencies,
            name,
            editable,
            isFinished,
            LabelComponent,
            loggedIn,
            onDisabledClick,
            option,
            pastDeadline,
            predictionStatus,
            subheadings = defaultSubheadings,
            status,
            topText,
            title
        } = this.props;
        return (
            <React.Fragment>
                <Input
                    type="radio"
                    hidden={true}
                    id={inputId}
                    defaultChecked={isSelected}
                    value={option}
                    {...{ name, disabled, predictionStatus, status }}
                />
                <LabelComponent
                    onClick={
                        !disabled
                            ? createSubmitFor(inputId)
                            : pastDeadline && loggedIn
                                ? onDisabledClick
                                : null
                    }
                    htmlFor={inputId}
                    frequency={loGet(frequencies, [option])}
                    {...{
                        disabled,
                        isCorrect,
                        predictionStatus,
                        status,
                        title
                    }}
                >
                    <Top data-hj-whitelist>{topText}</Top>
                    {(!!frequencies ||
                        ((editable || isSelected || isCorrect) &&
                            !(isFinished && isSelected && !isCorrect))) && (
                        <Bottom
                            {...{ disabled, isCorrect, frequencies }}
                            data-hj-whitelist
                        >
                            {subheadings[option]}
                        </Bottom>
                    )}
                </LabelComponent>
            </React.Fragment>
        );
    }
}

const isCorrectOption = ({ result, option }) => option === result;

const predictionStatus = ({ isSelected, prediction, result, status }) =>
    result && hasFinished(status)
        ? result === prediction && isSelected
            ? "CORRECT"
            : "INCORRECT"
        : "PENDING";

const mapState = (
    state,
    { id, editable, option, pastDeadline, prediction, result, status }
) => {
    const name = `match-${id}`;
    const isSelected = prediction === option;
    const isFinished = hasFinished(status);
    const loggedIn = isLoggedIn(state);
    return {
        disabled: !editable || pastDeadline || !loggedIn,
        inputId: `${name}-${option}`,
        isSelected,
        isCorrect: isCorrectOption({ option, result }),
        isFinished,
        loggedIn,
        name,
        predictionStatus: predictionStatus({
            isSelected,
            prediction,
            result,
            status
        }),
        status
    };
};

PillSection = connect(mapState)(PillSection);

export { PillSection };
