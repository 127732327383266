import {
    areNotificationsAvailable,
    hasNotificationPermission
} from "./request";
import { firebase } from "../../../utils/alias";
import { eventChannel } from "redux-saga";
import { fork, select, take } from "redux-saga/effects";
import { selectUid } from "../../reducers";
import { logError } from "../loggers";
import { safeUpdate } from "../firebaseDataSync/safeUpdate";

const nextTick = func => Promise.resolve().then(func);

const createTokenUpdateChannel = () =>
    eventChannel(emit => {
        return firebase
            .messaging()
            .onTokenRefresh(token => nextTick(() => emit({ token })));
    });

const getNextToken = () => firebase.messaging().getToken();

const updateToken = function*() {
    const token = yield getNextToken();
    yield sendTokensToDb({ [token]: true });
};

const sendTokensToDb = function*(tokens) {
    const uid = yield select(selectUid);
    const ref = firebase.database().ref(`fcmTokens/${uid}`);
    yield safeUpdate(ref, tokens);
};

const refreshToken = function*({ token, newToken }) {
    try {
        yield sendTokensToDb({ [newToken]: true, [token]: false });
        console.log("token changed", token, newToken);
    } catch (e) {
        console.log("get token failed", e);
    }
};

const initializeNotifications = function*() {
    try {
        if (areNotificationsAvailable() && hasNotificationPermission()) {
            let token = yield updateToken();
            const channel = createTokenUpdateChannel();
            while (true) {
                const { newToken } = yield take(channel);
                yield fork(refreshToken, { token, newToken });
                token = newToken;
            }
        }
    } catch (e) {
        yield logError(e);
    }
};

export { initializeNotifications };
