import React from "react";
import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { colours } from "../../predictions/styles/colours";

export const Root = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;

export const Tab = styled(Link).attrs({ exact: true })`
    color: ${colours.greys[6]};
    text-decoration: none !important;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    &.active {
        color: ${colours.primary[7]};
        border-bottom: solid 3px ${colours.primary[7]};
        border-top: solid 3px white;
        &:hover {
            color: ${colours.primary[5]};
            border-bottom-color: ${colours.primary[5]};
        }
    }
    &:hover {
        color: ${colours.greys[7]};
    }

    @media only screen and (max-width: 350px) {
        padding: 0 0.5rem;
        font-size: 0.9rem;
    }

    @media only screen and (max-width: 300px) {
        padding: 0 0.25rem;
        font-size: 0.8rem;
    }
`;

export const Tabs = () => (
    <Root>
        <Tab to="/">Home</Tab>
        <Tab to="/edit-predictions">Matches</Tab>
        <Tab to="/details">Leaderboard</Tab>
    </Root>
);
