import React from "react";
import { connect } from "react-redux";
import { hasPendingChanges } from "../../store/reducers/updates";

let DataUpdateStatus = ({ hasPendingChanges }) => (
    <span data-cy-pending={hasPendingChanges} />
);

const mapState = state => ({
    hasPendingChanges: hasPendingChanges(state)
});

DataUpdateStatus = connect(mapState)(DataUpdateStatus);

export { DataUpdateStatus };
