import { put, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import uniq from "uniqid";

const onRefresh = event => {
    event.preventDefault();
    event.returnValue = "";
};

const startWarnOnRefresh = () =>
    window.addEventListener("beforeunload", onRefresh);
const stopWarnOnRefresh = () =>
    window.removeEventListener("beforeunload", onRefresh);

const safeUpdate = function*(ref, value) {
    const updateId = uniq();

    yield put({ type: "UPDATE_STARTED", id: updateId });
    startWarnOnRefresh();

    const updateCompleted = eventChannel(emmitter => {
        ref.update(value, e => {
            if (e) {
                throw Error(e, "Update failed");
            }
            emmitter("Done");
        });
        return () => {};
    });
    yield take(updateCompleted);

    stopWarnOnRefresh();
    yield put({ type: "UPDATE_COMPLETE", id: updateId });
};

export { safeUpdate };
