import { takeEvery } from "redux-saga/effects";
import { firebase } from "../../../utils/alias";
import { dispatchOnValueChange } from "./firebaseEvents";
import { logError } from "../loggers";

const frequenciesRef = () =>
    firebase.database().ref(`crowdHistory/frequencies`);

const getFrequenciesResponse = frequencies => {
    return {
        type: "GET_FREQUENCIES_RESPONSE",
        frequencies
    };
};

const listenOnFrequenciesChange = function*() {
    try {
        const ref = frequenciesRef();
        yield dispatchOnValueChange(ref, getFrequenciesResponse);
    } catch (e) {
        yield logError(e);
    }
};

const watchUserThenListenFrequencies = takeEvery(
    "USER_FOUND",
    listenOnFrequenciesChange
);

export { watchUserThenListenFrequencies };
