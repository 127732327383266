import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Shootout } from "../components/shootout/Shootout";
import { MatchDetails } from "../components/shootout/MatchDetails";
import { selectNextUnpredictedMatch } from "../store/selectors/selectNextUnpredictedMatch";
import styled from "styled-components";
import { ShootoutIntro } from "../components/shootout/ShootoutIntro";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PredictionsShootout = () => {
    const dispatch = useDispatch();
    const match = useSelector(selectNextUnpredictedMatch);
    const [currentPrediction, setCurrentPrediction] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitPrediction = predictedValue => {
        const now = new Date();
        const { id } = match;
        const predictions = {
            [id]: {
                id,
                createdAt: now,
                changedAt: now,
                prediction: predictedValue
            }
        };
        dispatch({ type: "SUBMIT_PREDICTIONS", predictions, notify: false });
    };

    const onPredictionSubmit = predictedValue => async () => {
        setIsSubmitting(true);
        setCurrentPrediction(predictedValue);
        await new Promise(res => setTimeout(res, 1500));
        submitPrediction(predictedValue);
        setCurrentPrediction(null);
        setIsSubmitting(false);
    };
    useEffect(() => {
        document.ondragover = ({ pageX, pageY, ...rest }) =>
            console.log(pageX, pageY, rest);
    }, []);
    return (
        <Root>
            <ShootoutIntro />
            <MatchDetails
                {...{ match, onPredictionSubmit, currentPrediction }}
            />
            <Shootout
                key={match.id}
                {...{ match, onPredictionSubmit, isSubmitting }}
            />
        </Root>
    );
};

export { PredictionsShootout };
