import { cancel } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { logError } from "../loggers";

function* cancelSaga(task) {
    yield cancel(task);
}

const nextTick = func => Promise.resolve().then(func);

const handleChange = actionCreator =>
    function*({ snapshot }) {
        const value = snapshot.val();
        yield put(actionCreator(value));
    };

const createValueChannel = ref =>
    eventChannel(emit => {
        return ref.on("value", snapshot => nextTick(() => emit({ snapshot })));
    });

const cancelOnLogout = task => takeLatest("LOGOUT", cancelSaga, task);

const dispatchOnValueChange = function*(
    ref,
    actionCreator,
    { keepOnLogout = false } = {}
) {
    try {
        const task = yield takeEvery(
            createValueChannel(ref),
            handleChange(actionCreator)
        );
        if (!keepOnLogout) {
            yield cancelOnLogout(task);
        }
    } catch (e) {
        console.error('happened in dispatch on value change')
        yield logError(e);
    }
};

export { dispatchOnValueChange };
