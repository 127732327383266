import { takeEvery } from "redux-saga/effects";
import { firebase } from "../../../utils/alias";
import { dispatchOnValueChange } from "./firebaseEvents";
import sortBy from "lodash.sortby";

const leaderboardRef = () => firebase.database().ref(`leaderboard/1`);

const getLeaderboardResponse = users => {
    let userIds;
    if (users) {
        userIds = Object.keys(users);

        userIds = userIds.filter(id => users[id].displayName);
        userIds = sortBy(userIds, [id => users[id].implicitPosition]);
    } else {
        users = {};
        userIds = [];
    }
    return { type: "GET_LEADERBOARD_RESPONSE", users, userIds };
};

const listenOnLeaderboardChange = function*() {
    const ref = leaderboardRef();
    yield dispatchOnValueChange(ref, getLeaderboardResponse);
};

const watchUserThenListenOnLeaderboard = takeEvery(
    "USER_FOUND",
    listenOnLeaderboardChange
);

export { watchUserThenListenOnLeaderboard };
