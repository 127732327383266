import styled from "styled-components";
import { Link } from "react-router-dom";
import { colours } from "../../predictions/styles/colours";

export const Root = styled.div`
    border-top: solid 4px ${colours.primary[5]};
    background-color: white;
    box-shadow: 0 0.1rem 0.2rem ${colours.greys[5]},
        0 0.2rem 0.2rem ${colours.greys[3]};
`;

export const AppBarTop = styled.div`
    height: 4rem;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 800px) {
        height: 3rem;
    }
`;

export const AppBarBottom = styled.div`
    height: 3rem;
`;

export const AppTitle = styled(Link)`
    color: ${colours.primary[9]};
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 0.5rem;
    padding-right: 1rem;
    &:hover {
        background-color: ${colours.primary[1]};
    }

    @media only screen and (max-width: 440px) {
        font-size: 1.2rem;
    }
    @media only screen and (max-width: 400px) {
        font-size: 1.1rem;
    }
    @media only screen and (max-width: 350px) {
        font-size: 1rem;
    }
    @media only screen and (max-width: 330px) {
        font-size: 0.8rem;
    }
`;

export const Logo = styled.img`
    height: 2rem;
    margin: 0 0.5rem;
    @media only screen and (max-width: 440px) {
        height: 1.5rem;
    }
    @media only screen and (max-width: 400px) {
        height: 1rem;
    }
    @media only screen and (max-width: 350px) {
        height: 0.5rem;
    }
    @media only screen and (max-width: 330px) {
        display: none;
    }
`;
