import { fork, select, takeEvery } from "redux-saga/effects";
import { selectUid, currentUserPredictions } from "../reducers";
import { predictionsRef } from "./firebaseDataSync/predictions";
import { postNewUserDetails } from "./postNewUserDetails";
import { alertSaved } from "./notifications/appNotifications";
import { safeUpdate } from "./firebaseDataSync/safeUpdate";

const submitPredictions = function*({ predictions, notify = true }) {
    const uid = yield select(selectUid);
    if (!uid) {
        return;
    }
    yield safeUpdate(predictionsRef(uid), predictions);
    if (notify) {
        yield fork(alertSaved);
    }
    predictions = yield select(currentUserPredictions);
    const predictionsMade = Object.keys(predictions).reduce(
        (mem, p) =>
            predictions[p] && predictions[p].prediction ? mem + 1 : mem,
        0
    );
    yield postNewUserDetails({
        uid,
        predictionsMade
    });
};

const watchSubmitPredictions = takeEvery(
    "SUBMIT_PREDICTIONS",
    submitPredictions
);

export { watchSubmitPredictions };
