import React, { useEffect, useRef } from "react";
import { Layout } from "../components/layout/Layout";
import { useForm } from "react-hook-form";
import { leaderboardsRef } from "../store/sagas/firebaseDataSync/leaderboards";
import { currentUserRef } from "../store/sagas/firebaseDataSync/currentUser";
import firebase from "firebase/app"
import { selectUid, userLeaderboards } from "../store/reducers";
import { useSelector } from "react-redux";
import { history } from "../store/history";
import { nanoid } from "nanoid";
import { colours } from "../components/predictions/styles/colours";
import { Button } from "../components/styles/styles";
import { styled } from "../utils/alias";

const Input = styled.input`
    padding: 6px;
    margin-right: 1rem;
`;

export const CreateLeaderboard = () => {
    const { handleSubmit, register, errors } = useForm();
    const inputRef = useRef();
    const userId = useSelector(selectUid);
    const availableBoards = useSelector(userLeaderboards);
    useEffect(() => {
        inputRef.current.focus();
    }, []);
    const submit = async ({ name }) => {
        const leaderboardId = nanoid(7);
        await leaderboardsRef()
            .child(leaderboardId)
            .set({
                id: leaderboardId,
                name,
                createdBy: userId,
                createdAt: firebase.database.ServerValue.TIMESTAMP,
                userIds: [userId]
            });
        await currentUserRef(userId).update({
            leaderboards: [...(availableBoards || []), leaderboardId]
        });
        history.push("/details?t=" + leaderboardId);
    };
    return (
        <Layout>
            <form onSubmit={handleSubmit(submit)}>
                <Input
                    name="name"
                    autoComplete="off"
                    placeholder="Leaderboard name"
                    innerRef={e => {
                        inputRef.current = e;
                        register(e, {
                            required: "Please enter a name for the leaderboard",
                            minLength: {
                                value: 3,
                                message: "3 characters minimum"
                            },
                            maxLength: {
                                value: 15,
                                message: "15 characters max"
                            }
                        });
                    }}
                />
                <Button type="submit">Create</Button>
                {errors &&
                    errors.name && (
                        <div style={{ color: colours.negative[9] }}>
                            {errors.name.message}
                        </div>
                    )}
            </form>
        </Layout>
    );
};
