import { firebase } from "../../../utils/alias";
import { initializeNotifications } from "./initializeNotifications";
import { takeEvery } from "redux-saga/effects";

// const areNotificationsStandard = () => {
//     return "serviceWorker" in navigator && "PushManager" in window;
// };

const isElectron = () =>
    navigator.userAgent.toLowerCase().indexOf(" electron/") > -1;

const areNotificationsAvailable = () => {
    return false; //TODO unblock notifications maybe?
    // return (
    //     "Notification" in window && Notification && areNotificationsStandard()
    // );
};

const isNotificationPermission = value =>
    "Notification" in window &&
    Notification &&
    Notification.permission === value;

const hasNotificationPermission = () =>
    !isElectron() && isNotificationPermission("granted");
const isNotificationBlocked = () =>
    !isElectron() && isNotificationPermission("denied");

const requestNotificationPermissions = function*() {
    try {
        const messaging = firebase.messaging();
        yield messaging.requestPermission();
        yield initializeNotifications();
    } catch (error) {
        console.error(error);
    }
};

const watchNotificationRequest = takeEvery(
    "REQUEST_NOTIFICATION_PERMISSIONS",
    requestNotificationPermissions
);

export {
    requestNotificationPermissions,
    areNotificationsAvailable,
    hasNotificationPermission,
    isNotificationBlocked,
    watchNotificationRequest
};
