import React from "react";
import { Layout } from "../components/layout/Layout";

let Send = () => {
    //TODO: implement
    return (
        <Layout>
            <h2>Send notification</h2>
        </Layout>
    );
};

export { Send };
