import React from "react";
import { styled } from "../../utils/alias";
import { connect } from "react-redux";
import { userAchievements } from "../../store/reducers/leaderboard";
import { AchievementBadge } from "./Acheivement";

const Root = styled.div``;

const Content = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const connectRedux = connect((state, { displayName }) => ({
    achievements: userAchievements(displayName)(state)
}));

export const Achievements = connectRedux(({ achievements = [] }) => {
    let content;
    if (!achievements[0]) {
        content = "Nothing to see here 🙄";
    } else {
        content = achievements.map(a => <AchievementBadge type={a.type} />);
    }

    return (
        <Root>
            <h3>Achievements</h3>
            <Content>{content}</Content>
        </Root>
    );
});
