import styled from "styled-components";
import React from "react";

const FieldDiv = styled.div`
    margin-top: 1rem;
`;

let ErrorDiv = styled.div`
    color: hsl(0, 89%, 15%);
    em {
        color: hsl(0, 95%, 45%);
    }
    font-weight: bold;
    border: solid red 1px;
    border-radius: 0.5em;
    box-sizing: border-box;
    display: inline-block;
    margin-top: 1em;
    padding: 1em;
    width: 20em;
`;

const Error = ({ children }) => (
    <ErrorDiv className="error">{children}</ErrorDiv>
);

export { Error, FieldDiv };
