import React from "react";
import { styled } from "../../utils/alias";
import { PopDetails } from "../PopDetails";

const Badge = styled.i`
    cursor: pointer;
`;

export const CompletedPredictionsBadge = ({
    matchdaysAheadPredicted,
    predictionsMade
}) => {
    if (!matchdaysAheadPredicted || matchdaysAheadPredicted === 0) return null;
    let config = {};
    if (predictionsMade === 380) {
        config = {
            starType: "",
            details: "Entered predictions for the whole season"
        };
    } else if (matchdaysAheadPredicted >= 5) {
        config = {
            starType: "is-half",
            details: "Entered predictions for the next 5 match days"
        };
    } else if (matchdaysAheadPredicted >= 3) {
        config = {
            starType: "is-transparent",
            details: "Entered predictions for the next 3 match days"
        };
    } else if (matchdaysAheadPredicted >= 1) {
        config = {
            starType: "is-empty",
            details: "Entered predictions for the next match day"
        };
    }

    return (
        <PopDetails
            icon={
                <Badge
                    className={"nes-icon star is-small " + config.starType}
                />
            }
            details={config.details}
        />
    );
};
