import React, { Fragment } from "react";
import { Alerts } from "./alerts/Alerts";
import { DataUpdateStatus } from "./DataUpdateStatus";

export const AppUtilities = () => (
    <Fragment>
        <Alerts />
        <DataUpdateStatus />
    </Fragment>
);
