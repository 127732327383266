import Colour from "color";
import styled from "styled-components";

const alertGreen = Colour("#56b800").toString();
const alertBorderGreen = Colour(alertGreen)
    .darken(0.2)
    .toString();

const Alert = styled.div`
    background-color: ${alertGreen};
    border: 1px solid ${alertBorderGreen};
    border-radius: 0.75rem;
    margin-top: 0.5rem;
    color: white;

    padding: 0.5rem;
`;

const Actions = styled.div`
    margin-top: 0.5rem;
    text-align: right;
`;

const SmallButton = styled.button`
    border: solid 1px ${alertBorderGreen};
    box-sizing: border-box;
    background: none;
    background-color: white;
    border-radius: 0.75rem;
    color: ${alertBorderGreen};
    cursor: pointer;
    height: 1.5rem;
    margin-left: 1rem;
    line-height: 1.3rem;
`;

const Bold = styled.span`
    font-weight: bold;
`;

export { Actions, Alert, SmallButton, Bold };
