import React from "react";
import { styled } from "../../utils/alias";

const achievementDetails = {
    10: {
        badge: "💯",
        title: "10/10 Matchday",
        description: "Got all 10 predictions right for a matchday"
    },
    9: {
        badge: "🚬",
        title: "9/10 Matchday",
        description: "Got 9 predictions right out of 10 for a matchday"
    },
    0: {
        badge: "🥯",
        title: "0/10 Matchday",
        description: "Got all predictions wrong for a matchday"
    },
    seasonWin: {
        badge: "🏆",
        title: "Season Winner",
        description: "Won a whole season"
    },
    onlyCorrect: {
        badge: "🏝",
        title: "Only one",
        description: "Only person to predict a match correctly"
    }
};

const Root = styled.div``;

const BadgeRoot = styled.div`
    font-size: 3rem;
`;

export const AchievementBadge = ({ type }) => {
    const achievement = achievementDetails[type];
    return (
        <Root>
            <BadgeRoot>{achievement.badge}</BadgeRoot>
            <div>{achievement.title}</div>
        </Root>
    );
};
