import { applyMiddleware, compose, createStore } from "redux";
import { rootReducer } from "./reducers";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./sagas";
import { createLogger } from "redux-logger";

const createAppStore = () => {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(createLogger(), sagaMiddleware))
    );
    sagaMiddleware.run(rootSaga);
    return store;
};

export { createAppStore };
