import React from "react";
import { connect } from "react-redux";
import { Actions, Alert, Bold, SmallButton } from "./styles";

let NotificationRequest = ({ setClicked, requestNotificationPermissions }) => (
    <Alert>
        <div>
            <Bold>Activate alerts</Bold> to keep up to date with your progress
            and get reminders so you don't miss a prediction:
        </div>
        <Actions>
            <SmallButton
                onClick={() => {
                    requestNotificationPermissions();
                    setClicked(true);
                }}
            >
                Activate
            </SmallButton>
            <SmallButton onClick={setClicked}>Not now</SmallButton>
        </Actions>
    </Alert>
);

const mapState = state => ({});

const dispatchActions = {
    requestNotificationPermissions: () => ({
        type: "REQUEST_NOTIFICATION_PERMISSIONS"
    })
};

NotificationRequest = connect(
    mapState,
    dispatchActions
)(NotificationRequest);

export { NotificationRequest };
