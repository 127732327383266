export const gameStatuses = {
    SCHEDULED: "SCHEDULED",
    CANCELLED: "CANCELLED",
    POSTPONED: "POSTPONED",
    SUSPENDED: "SUSPENDED",
    IN_PLAY: "IN_PLAY",
    PAUSED: "PAUSED",
    FINISHED: "FINISHED",
    AWARDED: "AWARDED"
};

// eslint-disable-next-line
const allStatuses = [
    "SCHEDULED",
    "CANCELLED",
    "POSTPONED",
    "SUSPENDED",
    "IN_PLAY",
    "PAUSED",
    "FINISHED",
    "AWARDED"
];

const notStartedStatuses = ["SCHEDULED", "CANCELLED", "POSTPONED", "SUSPENDED"];

const inProgressStatuses = ["IN_PLAY", "PAUSED"];

const hasFinishedStatuses = ["FINISHED", "AWARDED"];

export const hasStarted = status => !notStartedStatuses.includes(status);
export const isInProgress = status => inProgressStatuses.includes(status);
export const hasFinished = status => hasFinishedStatuses.includes(status);

export const isScheduled = status => status === gameStatuses.SCHEDULED;
