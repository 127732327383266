import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { predictionsMade } from "../store/reducers";
import styled from "styled-components";

const Root = styled.div`
    margin: 3rem 0;
`;

const Title = styled.div`
    font-family: "Press Start 2P";
    font-size: 0.7rem !important;
`;

const _InputProgress = ({ predictionsMade = 0 }) => {
    const completed = predictionsMade === 380;
    return (
        <Root data-hj-whitelist className="nes-container with-title">
            <Title className="title">
                You've entered{" "}
                {!completed && (
                    <span>{predictionsMade} predictions out of 380</span>
                )}
                {completed && <span>All 380 predictions</span>}
            </Title>
            <div>
                <progress
                    data-hj-whitelist
                    className={
                        "nes-progress " +
                        (completed ? "is-success" : "is-primary")
                    }
                    value={predictionsMade}
                    max={380}
                />
            </div>
            {!completed && (
                <div>
                    <Link to="/edit-predictions">Finish now</Link> to make sure
                    you don't miss out on any points.
                </div>
            )}
            You can change your predictions any time until 23:59 the night
            before each match.
        </Root>
    );
};

const InputProgress = connect(state => ({
    predictionsMade: predictionsMade(state)
}))(_InputProgress);

export { InputProgress };
