import React, { Fragment, useState, useCallback } from "react";
import { connect } from "react-redux";
import { PredictionsForm } from "../predictions/PredictionsForm";
import { history } from "../../store/history";
import { withinBounds } from "../../utils/withinBounds";
import { ExpandingBigButton } from "./styles";
import { PaginationButtonsSection } from "./PaginationButtonsSection";
import { selectInitialMatchdayToEdit } from "../../store/selectors/selectInitialMatchdayToEdit";
import styled from "styled-components";
import { colours } from "../predictions/styles/colours";
import { Error } from "../FieldDiv";

const MatchdayLabel = styled.div`
    color: ${colours.greys[5]};
    margin-bottom: -0.75rem;
`;

const MatchdayValue = styled.h3`
    color: ${colours.greys[8]};
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: 500;
`;

let EditPredictionsByMatchday = ({ initialMatchday }) => {
    const [matchday, setMatchday] = useState(initialMatchday);
    const changeMatchDayBy = amount => () =>
        setMatchday(matchday =>
            withinBounds(matchday + amount, {
                min: 1,
                max: 38
            })
        );
    const nextMatchday = useCallback(() => {
        changeMatchDayBy(1)();
        window.scrollTo(0, 0);
    });
    return (
        <Fragment>
            <PaginationButtonsSection changeMatchDayBy={changeMatchDayBy}>
                <MatchdayLabel>matchday</MatchdayLabel>
                <MatchdayValue>{matchday}</MatchdayValue>
            </PaginationButtonsSection>
            <PredictionsForm
                editable={true}
                matchday={matchday}
                next={nextMatchday}
            />
            <PaginationButtonsSection changeMatchDayBy={changeMatchDayBy}>
                <ExpandingBigButton onClick={() => history.push("/")}>
                    Home
                </ExpandingBigButton>
            </PaginationButtonsSection>
        </Fragment>
    );
};

const mapState = state => {
    return {
        initialMatchday: selectInitialMatchdayToEdit(state)
    };
};

EditPredictionsByMatchday = connect(mapState)(EditPredictionsByMatchday);

export { EditPredictionsByMatchday };
