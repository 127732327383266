import React, { useEffect } from "react";
import { Layout } from "../components/layout/Layout";
import { leaderboardsRef } from "../store/sagas/firebaseDataSync/leaderboards";
import { currentUserRef } from "../store/sagas/firebaseDataSync/currentUser";
import { selectUid, userLeaderboards } from "../store/reducers";
import { leaderboards } from "../store/reducers/leaderboard";
import { useSelector } from "react-redux";
import { history } from "../store/history";

const addIfNew = (array, item) => {
    if (array.includes(item)) {
        return array;
    }
    return [...array, item];
};

export const JoinLeaderboard = () => {
    const userId = useSelector(selectUid);
    const availableBoards = useSelector(userLeaderboards) || [];
    const allLeaderboards = useSelector(leaderboards);

    useEffect(
        () => {
            const joinLeaderBoard = async () => {
                const pathParts = window.location.pathname.split("/");
                const leaderboardId = pathParts[pathParts.length - 1];
                const currentUserIds = Object.values(
                    allLeaderboards[leaderboardId].userIds
                );
                await leaderboardsRef()
                    .child(leaderboardId + "/userIds")
                    .set(addIfNew(currentUserIds, userId));

                await currentUserRef(userId).update({
                    leaderboards: addIfNew(availableBoards, leaderboardId)
                });
                history.push("/details?t=" + leaderboardId);
            };
            if (userId) {
                joinLeaderBoard();
            }
        },
        [userId]
    );
    return <Layout>Joining...</Layout>;
};
