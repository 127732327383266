export const teamNames = {
    "Arsenal FC": "Arsenal",
    "Aston Villa FC": "Aston Villa",
    "AFC Bournemouth": "Bournemouth",
    "Brighton & Hove Albion FC": "Brighton",
    "Burnley FC": "Burnley",
    "Brentford FC": "Brentford FC",
    "Chelsea FC": "Chelsea",
    "Crystal Palace FC": "Crystal Palace",
    "Everton FC": "Everton",
    "Fulham FC": "Fulham",
    "Leeds United FC": "Leeds United",
    "Leicester City FC": "Leicester City",
    "Liverpool FC": "Liverpool",
    "Luton Town FC": "Luton Town",
    "Manchester City FC": "Manchester City",
    "Manchester United FC": "Manchester United",
    "Newcastle United FC": "Newcastle United",
    "Norwich City FC": "Norwich City",
    "Nottingham Forest FC": "Nottingham Forest",
    "Sheffield United FC": "Sheffield United",
    "Southampton FC": "Southampton",
    "Tottenham Hotspur FC": "Tottenham Hotspur",
    "Watford FC": "Watford",
    "West Bromwich Albion FC": "West Brom",
    "West Ham United FC": "West Ham",
    "Wolverhampton Wanderers FC": "Wolverhampton"
};
