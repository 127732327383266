import React from "react";
import logo from "../../logo.svg";
import { UserActions } from "./UserActions/UserActions";
import { Grow } from "../../styles/flexBox";
import { AppBarTop, AppTitle, Logo, Root, AppBarBottom } from "./Header.styles";
import { Tabs } from "./Tabs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../store/reducers";
import { ShootoutLink } from "./ShootoutLink";

export const Header = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isTablet = useMediaQuery("(max-width:800px)");
    const isMobile = useMediaQuery("(max-width:600px)");
    return (
        <Root>
            <AppBarTop>
                <AppTitle to="/">
                    <Logo src={logo} />
                    Predictable
                </AppTitle>
                <Grow>{!isTablet && isLoggedIn && <Tabs />}</Grow>
                {(!isMobile || isLoggedIn) && <ShootoutLink />}
                <UserActions />
            </AppBarTop>
            {isTablet &&
                isLoggedIn && (
                    <AppBarBottom>
                        <Tabs />
                    </AppBarBottom>
                )}
        </Root>
    );
};
