import { matchesById, matchIds, currentUserPredictions } from "../reducers";
import { isAfter } from "../../utils/date";
import { startOfDay } from "date-fns";


const hasDeadlinePassedForMatch = match => {
    if (!match || !match.time) {
        return false;
    }
    const { time: matchTime } = match;
    const deadline = startOfDay(matchTime);
    return isAfter(new Date(), deadline);
};

export const selectNextUnpredictedMatch = state => {
    const byId = matchesById(state);
    const ids = matchIds(state);
    const predictions = currentUserPredictions(state);

    const id = ids.find(id => {
        const match = byId[id];
        const hasMadePrediction = predictions[id] && predictions[id].prediction;
        const hasDeadlinePassed = hasDeadlinePassedForMatch(match);
        const isPostponed = match.status === "POSTPONED";
        const isPredictionEditable = !hasDeadlinePassed || isPostponed;
        return isPredictionEditable && !hasMadePrediction;
    });
    return byId[id];
};
