import React from "react";
import { Link } from "react-router-dom";
import { BigHeaderUserActions } from "./BigHeaderUserActions";
import styled from "styled-components";
import { Logo } from "../../Logo";

const AppHeader = styled.header`
    background-color: rgb(61, 100, 157);
    box-shadow: rgb(28, 47, 76) 0px -1.5rem 3rem -1rem inset;
    color: white;
    padding: 20px;
`;

const HomeDiv = styled.div`
    margin-top: 1rem;
`;

const AppTitle = styled.h1`
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    margin: 0;
    display: inline-block;
`;

const HeaderLink = styled(Link)`
    color: white !important;
    text-decoration: none !important;
`;

export const BigHeader = () => (
    <AppHeader>
        <BigHeaderUserActions />
        <HomeDiv>
            <Link to="/">
                <Logo />
            </Link>
            <div>
                <HeaderLink to="/">
                    <AppTitle>Predictable</AppTitle>
                </HeaderLink>
            </div>
        </HomeDiv>
    </AppHeader>
);
