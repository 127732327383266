import { select, takeEvery } from "redux-saga/effects";
import { selectUid } from "../../reducers/index";
import { firebase } from "../../../utils/alias";
import { dispatchOnValueChange } from "./firebaseEvents";
import { logError } from "../loggers";

const permissionsRef = uid => firebase.database().ref(`permissions/${uid}`);

const getPermissionsResponse = permissions => {
    return {
        type: "GET_PERMISSIONS_RESPONSE",
        permissions
    };
};

const listenOnPermissionsChange = function*() {
    try {
        const uid = yield select(selectUid);
        const ref = permissionsRef(uid);
        yield dispatchOnValueChange(ref, getPermissionsResponse);
    } catch (e) {
        yield logError(e);
    }
};

const watchUserThenListenPermissions = takeEvery(
    "USER_FOUND",
    listenOnPermissionsChange
);

export { permissionsRef, watchUserThenListenPermissions };
