const eqIfLess = (value, min) => (value < min ? min : value);
const eqIfMore = (value, max) => (value > max ? max : value);

const withinBounds = (value, { min, max }) => {
    if (min) {
        value = eqIfLess(value, min);
    }
    if (max) {
        value = eqIfMore(value, max);
    }
    return value;
};

export { eqIfLess, eqIfMore, withinBounds };
