import { select, takeEvery } from "redux-saga/effects";
import { firebase } from "../../utils/alias";
import pickBy from "lodash.pickby";
import { format } from "../../utils/date";

const createLogger = resource =>
    function*(data = {}) {
        const store = yield select();
        const timeId = format(new Date(), "YYYY-MM-DDTHH:mm:ss-SSSZ");
        const resourceRef = firebase
            .database()
            .ref(`logs/${resource}/${timeId}`);

        try {
            yield resourceRef.set({
                data,
                //store
            });
        } catch (e) {
            console.log("Couldn't log to the server:", data);
        }
    };

const errorLogger = createLogger("error");
const logError = function*(error) {
    try {
        console.error(error);
        error = pickBy();
        error = pickBy(error, value => typeof value !== "function");
        yield errorLogger(error);
    } catch (e) {
        console.error(e);
    }
};

const logPredictionsSubmit = createLogger("predictionsSubmit");
const logLogin = createLogger("login");
const logLogout = createLogger("logout");
const logRegister = createLogger("register");

const watchLogError = takeEvery("LOG_ERROR", ({ error }) => logError(error));

export {
    createLogger,
    logError,
    logLogin,
    logLogout,
    logPredictionsSubmit,
    logRegister,
    watchLogError
};
