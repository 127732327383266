import Colour from "color";

const brightBlue = Colour("#0072ce")
    .lighten(0.1)
    .toString();
const blueHoverColour = Colour(brightBlue)
    .alpha(0.2)
    .toString();
const feintBorderColour = Colour(brightBlue)
    .alpha(0.5)
    .toString();
const mediumTextColour = Colour("#000")
    .alpha(0.5)
    .toString();
const lightGrey = Colour("#000")
    .alpha(0.3)
    .toString();

const liveMatchColour = "#00ff12";
const correctColour = "#00be10";
const incorrectColour = "#e30000";

export const colours = {
    white: "hsl(240, 0%, 100%)",
    greys: {
        1: "hsl(240, 70%, 98%)",
        2: "hsl(240, 70%, 95%)",
        3: "hsl(240, 10%, 90%)",
        4: "hsl(240, 10%, 85%)",
        5: "hsl(240, 10%, 80%)",
        6: "hsl(240, 10%, 70%)",
        7: "hsl(240, 10%, 55%)",
        8: "hsl(240, 10%, 35%)",
        9: "hsl(240, 10%, 25%)"
    },
    primary: {
        1: "hsl(204, 100%, 95%)",
        3: "hsl(207, 95%, 50%)",
        5: "hsl(211, 90%, 45%)",
        7: "hsl(207, 80%, 40%)",
        8: "hsl(207, 70%, 45%)",
        9: "hsl(207, 90%, 30%)"
    },
    positive: {
        1: "hsl(120, 100%, 95%)",
        2: "hsl(120, 80%, 87%)",
        3: "hsl(120, 80%, 80%)",
        4: "hsl(120, 80%, 70%)",
        5: "hsl(120, 90%, 45%)",
        6: "hsl(120, 85%, 42%)",
        7: "hsl(120, 80%, 40%)",
        8: "hsl(120, 85%, 35%)",
        9: "hsl(120, 90%, 30%)"
    },
    negative: {
        1: "hsl(0, 100%, 95%)",
        3: "hsl(0, 80%, 80%)",
        5: "hsl(0, 90%, 45%)",
        7: "hsl(0, 80%, 40%)",
        8: "hsl(0, 70%, 45%)",
        9: "hsl(0, 90%, 30%)"
    }
};

export {
    brightBlue,
    blueHoverColour,
    correctColour,
    feintBorderColour,
    incorrectColour,
    mediumTextColour,
    liveMatchColour,
    lightGrey
};
