import Colour from "color";
import { colours } from "../predictions/styles/colours";
import styled, { css } from "styled-components";

const mainFontColour = Colour("#000")
    .alpha(0.7)
    .toString();

const inputStyles = css`
    background-color: white;
    border: none;
    border-radius: 1.5em;
    box-shadow: 0.1rem 0.1rem 0.1rem ${colours.greys[5]},
        0.3rem 0.3rem 0.3rem ${colours.greys[3]};
    box-sizing: border-box;
    color: ${mainFontColour};
    font-size: 1rem;
    height: 3rem;
    margin: 1rem;
    margin-bottom: 0;
    outline: none;
    font-weight: bold;
    padding: 0 1.5rem;
    width: 20rem;

    &:hover,
    &:focus {
        box-shadow: 0.1rem 0.1rem 0.2rem ${colours.greys[5]},
            0.5rem 0.5rem 0.5rem ${colours.greys[3]};
        color: black;
        outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-transition: color 9999s ease-out,
            background-color 9999s ease-out;
        -webkit-transition-delay: 9999s;
    }
`;

const Input = styled.input`
    ${inputStyles} width: 20;
`;

const BigButton = styled.button`
    ${inputStyles} cursor: pointer;
    width: 15rem;
    &:active {
        box-shadow: inset 0.1rem 0.1rem 0.2rem ${colours.greys[5]},
            inset 0.5rem 0.5rem 0.5rem ${colours.greys[3]};
    }
`;

export const Button = styled.button`
    cursor: pointer;
    border: solid 1px ${colours.primary[5]};
    color: ${colours.primary[5]};
    background-color: transparent;
    border-radius: 3px;
    padding: 5px 24px;
    &:hover {
        background-color: ${colours.primary[1]};
    }
`;

export { BigButton, Input, mainFontColour };
