import { select, takeEvery } from "redux-saga/effects";
import { selectUid } from "../../reducers/index";
import { firebase } from "../../../utils/alias";
import { dispatchOnValueChange } from "./firebaseEvents";
import { logError } from "../loggers";

const predictionsRef = uid => firebase.database().ref(`predictions/${uid}`);

const getPredictionsResponse = predictions => {
    return {
        type: "GET_PREDICTIONS_RESPONSE",
        predictions
    };
};

const listenOnPredictionsChange = function*() {
    try {
        const uid = yield select(selectUid);
        const ref = predictionsRef(uid);
        yield dispatchOnValueChange(ref, getPredictionsResponse);
    } catch (e) {
        yield logError(e);
    }
};

const watchUserThenListenPredictions = takeEvery(
    "USER_FOUND",
    listenOnPredictionsChange
);

export { predictionsRef, watchUserThenListenPredictions };
