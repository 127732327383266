import React, { useState } from "react";
import { Layout } from "../components/layout/Layout";
import { Leaderboard } from "../components/leaderboard/Leaderboard";
import { SelectLeaderboard } from "../components/leaderboard/select/SelectLeaderboard";
import { getSearchParamsObj } from "../store/sagas/authentication";

let DetailedLeaderboard = () => {
    const { t } = getSearchParamsObj();
    const [selectedLeaderboard, setSelectedLeaderboard] = useState(t || "1");
    return (
        <Layout>
            {
                <SelectLeaderboard
                    {...{ selectedLeaderboard, setSelectedLeaderboard }}
                />
            }
            <Leaderboard withDetails={true} {...{ selectedLeaderboard }} />
        </Layout>
    );
};

export { DetailedLeaderboard };
