import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import { PopDetails } from "../../components/PopDetails";

export const VerificationIcon = ({ emailVerified }) => {
    if (emailVerified) {
        return (
            <PopDetails
                details="Verified"
                icon={<i className="nes-icon is-small like" />}
            />
        );
    }
    return <PopDetails details="Not verified" icon={<ErrorIcon />} />;
};
