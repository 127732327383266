import { select, takeEvery } from "redux-saga/effects";
import { selectUid } from "../../reducers/index";
import { firebase } from "../../../utils/alias";
import { dispatchOnValueChange } from "./firebaseEvents";

export const currentUserRef = uid => firebase.database().ref(`users/${uid}`);

const getCurrentUserResponse = user => {
    return { type: "GET_CURRENT_USER_RESPONSE", user };
};

const listenOnUsersChange = function*() {
    const uid = yield select(selectUid);
    const ref = currentUserRef(uid);
    yield dispatchOnValueChange(ref, getCurrentUserResponse);
};

const watchUserThenListencurrentUser = takeEvery(
    "USER_FOUND",
    listenOnUsersChange
);

export { watchUserThenListencurrentUser };
