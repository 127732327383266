import React from "react";
import { connect } from "react-redux";
import { FieldDiv } from "../../components/FieldDiv";
import { selectUser } from "../../store/reducers";
import { PlainLink } from "../../components/styles/links";
import { Section } from "./styles";
import { VerificationIcon } from "./VerificationIcon";
import { RequestVerification } from "./RequestVerification";

const _UserDetailsSection = ({
    displayName,
    email,
    auth: { emailVerified }
}) => (
    <Section>
        <h3>Info</h3>
        <FieldDiv>
            User name: <span data-hj-suppress>{displayName}</span> (
            <PlainLink to={`/p/${displayName}`}>my profile</PlainLink>)
        </FieldDiv>
        <FieldDiv>
            <div>
                Email: {email} <VerificationIcon {...{ emailVerified }} />
            </div>
            {!emailVerified && <RequestVerification />}
        </FieldDiv>
    </Section>
);

const dispatchActions = {
    login: loginDetails => ({
        type: "LOGIN",
        loginDetails
    })
};

const mapState = state => ({
    ...selectUser(state)
});

const UserDetailsSection = connect(
    mapState,
    dispatchActions
)(_UserDetailsSection);

export { UserDetailsSection };
