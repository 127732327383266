import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
    userPredictions,
    matchesById,
    matchIdsForMatchday,
    notPostponedMatchIds
} from "../../store/reducers";
import styled from "styled-components";
import { Pill } from "./Inputs/Pill";
import get from "lodash.get";
import {
    setHours,
    setMinutes,
    isBefore,
    timeFormatDate,
    headingFormatDate
} from "../../utils/date";
import { colours } from "./styles/colours";

const DateHeading = styled.div`
    height: 1rem;
    margin: 0;
    margin-top: 1rem;
    margin-left: 1rem;
    text-align: left;
    color: ${colours.greys[6]};
`;

const justCompletedPage = (matchIds, predictions, changedId) => {
    const hadCompleted9 =
        matchIds.reduce((mem, id) => {
            const addedThis = get(predictions, [id]);
            return addedThis ? mem + 1 : mem;
        }, 0) === 9;
    const latestIsNew = !get(predictions, [changedId]);
    return hadCompleted9 && latestIsNew;
};

let PredictionsForm = ({
    editable,
    matchesById,
    next,
    predictions,
    submitPredictions,
    visibleMatchIds
}) => {
    return (
        <form
            id="predictionsForm"
            data-hj-whitelist
            onSubmit={e => {
                e.preventDefault();
                let changedId;
                const newPredictions = visibleMatchIds.reduce((mem, id) => {
                    const newPrediction = get(e, [
                        "target",
                        `match-${id}`,
                        "value"
                    ]);
                    const oldPrediction = get(predictions, [id, "prediction"]);
                    const oldCreatedAt = get(predictions, [id, "createdAt"]);
                    const isChanged =
                        newPrediction &&
                        (!oldPrediction || oldPrediction !== newPrediction);
                    if (isChanged) {
                        changedId = id;
                        const now = new Date();
                        return {
                            ...mem,
                            [id]: {
                                id,
                                createdAt: oldCreatedAt || now,
                                changedAt: now,
                                prediction: newPrediction
                            }
                        };
                    }
                    return mem;
                }, {});
                submitPredictions(newPredictions);
                if (
                    changedId &&
                    justCompletedPage(visibleMatchIds, predictions, changedId)
                ) {
                    setTimeout(next, 400);
                }
            }}
        >
            {visibleMatchIds.map((id, index) => {
                const match = matchesById[id];
                let { matchday, time } = match;
                const prevMatchId = visibleMatchIds[index - 1];
                const prevMatch = get(matchesById, prevMatchId);
                const prevmatchday = get(prevMatch, "matchday");
                if (matchday === prevmatchday) {
                    matchday = null;
                }
                const isPostponed = match.status === "POSTPONED";
                let date;
                const currentDate = new Date(time).getDate();
                const prevDate = new Date(get(prevMatch, "time")).getDate();
                if (currentDate !== prevDate) {
                    date = headingFormatDate(time);
                }
                const matchDate = setHours(setMinutes(time, 0), 0);
                const pastDeadline =
                    !isPostponed && !isBefore(new Date(), matchDate);
                const displayedMatchTime = isPostponed
                    ? "Postponed!"
                    : timeFormatDate(time);
                return (
                    <Fragment key={id}>
                        {date && (
                            <DateHeading
                                className="matchDate"
                                data-hj-whitelist
                            >
                                {date}
                            </DateHeading>
                        )}
                        <Pill
                            id={id}
                            displayedMatchTime={displayedMatchTime}
                            editable={editable}
                            pastDeadline={pastDeadline}
                            prediction={get(predictions, [id, "prediction"])}
                            {...match}
                        />
                    </Fragment>
                );
            })}
            <button id="predictionsFormSubmit" type="submit" hidden={true} />
        </form>
    );
};

const mapState = (state, { range = [0, 10], matchday }) => {
    let visibleMatchIds = [];
    if (matchday) {
        visibleMatchIds = matchIdsForMatchday(matchday)(state);
    } else {
        const allMatchIds = notPostponedMatchIds(state);
        visibleMatchIds = allMatchIds.slice(...range);
    }

    return {
        predictions: userPredictions(state),
        matchesById: matchesById(state),
        visibleMatchIds
    };
};

const dispatchActions = {
    submitPredictions: predictions => ({
        type: "SUBMIT_PREDICTIONS",
        predictions
    })
};

PredictionsForm = connect(
    mapState,
    dispatchActions
)(PredictionsForm);

export { PredictionsForm };
