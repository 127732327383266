import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Layout } from "../components/layout/Layout";
import { Error, FieldDiv } from "../components/FieldDiv";
import { formErrorMessage } from "../store/reducers";
import { BigButton, Input } from "../components/styles/styles";
import { Form } from "../components/Form";

const _Login = ({ formErrorMessage, login }) => (
    <Layout bigHeader={true}>
        <Form
            onSubmit={e => {
                e.preventDefault();
                login({
                    email: e.target.email.value,
                    password: e.target.password.value
                });
            }}
        >
            <FieldDiv>
                <Input type="email" placeholder="Email" name="email" />
            </FieldDiv>
            <FieldDiv>
                <Input type="password" placeholder="Password" name="password" />
            </FieldDiv>
            {formErrorMessage && (
                <FieldDiv>
                    <Error>{formErrorMessage}</Error>
                </FieldDiv>
            )}
            <FieldDiv>
                <BigButton>Login</BigButton>
            </FieldDiv>
            <FieldDiv>
                <Link to="/forgotten-password">Forgotten Password?</Link>
            </FieldDiv>
            <FieldDiv style={{ marginTop: "4rem" }}>
                Need an account?{" "}
                <Link to={`/register${window.location.search}`}>Register</Link>
            </FieldDiv>
        </Form>
    </Layout>
);

const dispatchActions = {
    login: loginDetails => ({
        type: "LOGIN",
        loginDetails
    })
};

const mapState = state => ({
    formErrorMessage: formErrorMessage(state)
});

const Login = connect(
    mapState,
    dispatchActions
)(_Login);

export { Login };
