import React from "react";
import { Link, Route } from "react-router-dom";
import { displayName, isLoggedIn } from "../../../store/reducers";
import { connect } from "react-redux";
import styled from "styled-components";
import { mainFontColour } from "../../styles/styles";
import Settings from "@material-ui/icons/Settings";

const HeaderLink = styled(Link)`
    color: white;
    text-decoration: underline;
    & + & {
        margin-left: 1rem;
    }
    &:hover {
        color: white !important;
    }
`;

const LogoutButton = styled.button`
    background-color: white;
    border: none;
    border-radius: 0.75rem;
    color: ${mainFontColour};
    cursor: pointer;
    font-size: 1rem;
    height: 1.5rem;
    outline: none;
    padding: 0 1rem;

    &:hover {
        color: black;
    }

    &:active {
        box-shadow: inset 2px 2px 5px ${mainFontColour};
    }
`;

const _UserActions = ({ displayName, isLoggedIn, logout }) => (
    <div>
        {isLoggedIn ? (
            <div>
                <HeaderLink to={"/account"} id="displayName">
                    <span data-hj-suppress>{displayName}</span> <Settings />
                </HeaderLink>{" "}
                | <LogoutButton onClick={logout}>Logout</LogoutButton>
            </div>
        ) : (
            <div>
                <Route
                    component={({ location }) =>
                        location.pathname.includes("/login") ||
                        location.pathname.includes("/register") ? null : (
                            <>
                                <HeaderLink to="/login">Login</HeaderLink>
                                <HeaderLink to="/register">Register</HeaderLink>
                            </>
                        )
                    }
                />
            </div>
        )}
    </div>
);

const mapState = state => ({
    isLoggedIn: isLoggedIn(state),
    displayName: displayName(state)
});

const dispatchActions = {
    logout: () => ({
        type: "LOGOUT"
    })
};

const BigHeaderUserActions = connect(
    mapState,
    dispatchActions
)(_UserActions);

export { BigHeaderUserActions };
