import styled from "styled-components";
import React from "react";
import logo from "./logo.svg";

const SpinningImage = styled.img`
    animation: App-logo-spin infinite ${({ spinDuration }) => spinDuration}s
        linear;
    height: 60px;
    width: 60px;
`;

const Logo = ({ spinDuration = 20 }) => (
    <SpinningImage spinDuration={spinDuration} src={logo} alt="logo" />
);

export { Logo };
