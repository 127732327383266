import React from "react";
import { Link } from "react-router-dom";
import { styled } from "../../utils/alias";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { colours } from "../predictions/styles/colours";

export const PlainLink = styled(Link)`
    color: inherit !important;
`;

const Button = styled(Link)`
    margin-bottom: 2rem;
    display: inline-block;
    color: ${colours.primary[9]};
    font-family: sans-serif;
    font-variant: small-caps;
    text-decoration: none !important;
    padding: 0.25rem;
    padding-left: 0.5rem;

    &:hover {
        color: ${colours.primary[8]};
        background-color: ${colours.primary[1]};
    }
`;

export const ShowMoreButton = props => (
    <Button {...props}>
        show more <ExpandMoreIcon />
    </Button>
);

export { PlainLink as Link };
