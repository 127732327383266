import { combineReducers } from "redux";

const MATCHES = (state = true, action) => {
    switch (action.type) {
        case "GET_MATCHES_RESPONSE":
        case "NO_USER_FOUND":
            return false;
        default:
            return state;
    }
};

const USER = (state = true, action) => {
    switch (action.type) {
        case "LOGIN":
        case "LOGOUT":
        case "REGISTER":
            return true;
        case "USER_FOUND":
        case "NO_USER_FOUND":
        case "FORM_FAIL":
            return false;
        default:
            return state;
    }
};

const CURRENT_USER = (state = true, action) => {
    switch (action.type) {
        case "USER_FOUND":
            return true;
        case "GET_CURRENT_USER_RESPONSE":
        case "NO_USER_FOUND":
        case "LOGOUT":
            return false;
        default:
            return state;
    }
};

const LEADERBOARD = (state = true, action) => {
    switch (action.type) {
        case "USER_FOUND":
            return true;
        case "GET_LEADERBOARD_RESPONSE":
        case "NO_USER_FOUND":
        case "LOGOUT":
            return false;
        default:
            return state;
    }
};

const LEADERBOARDS = (state = true, action) => {
    switch (action.type) {
        case "USER_FOUND":
            return true;
        case "GET_LEADERBOARDS_RESPONSE":
        case "NO_USER_FOUND":
        case "LOGOUT":
            return false;
        default:
            return state;
    }
};

const PERMISSIONS = (state = true, action) => {
    switch (action.type) {
        case "USER_FOUND":
            return true;
        case "LOGOUT":
        case "NO_USER_FOUND":
        case "GET_PERMISSIONS_RESPONSE":
            return false;
        default:
            return state;
    }
};

const PREDICTIONS = (state = true, action) => {
    switch (action.type) {
        case "USER_FOUND":
        case "LOGIN":
            return true;
        case "LOGOUT":
        case "NO_USER_FOUND":
        case "REGISTER":
        case "GET_PREDICTIONS_RESPONSE":
        case "FORM_FAIL":
            return false;
        default:
            return state;
    }
};

const resources = combineReducers({
    MATCHES,
    USER,
    CURRENT_USER,
    LEADERBOARD,
    LEADERBOARDS,
    PERMISSIONS,
    PREDICTIONS
});

export { resources };
