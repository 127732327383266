import React, { useState } from "react";
import {
    Centre,
    Left,
    Right,
    SectionContainer,
    MatchContainer
} from "../styles/inputs";
import { PillSection } from "./PillSection";
import {
    hasStarted,
    hasFinished,
    isInProgress
} from "../../../utils/football-data-org/statuses";
import { teamNames } from "../../../utils/football-data-org/teamNames";
import { Link } from "react-router-dom";
import { lightGrey, colours } from "../styles/colours";
import styled from "styled-components";
import { Grow } from "../../styles/flexBox";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { LiveIndicator } from "./LiveIndicator";

const TimeHeading = styled.div`
    font-size: 0.8rem;
    height: 1.1rem;
    text-align: left !important;
    vertical-align: middle;
    a {
        color: ${lightGrey};
        text-decoration: none;
        white-space: nowrap;
    }
`;
const PredictionError = styled.div`
    font-size: 0.8rem;
    height: 1.1rem;
    vertical-align: middle;
    margin-left: 16px;
    color: red;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const MatchHeader = styled.div`
    display: flex;
    background-color: white;
    height: 2rem;
    align-items: center;
    padding: 0 1rem;
    border-bottom: solid 1px ${colours.greys[1]};
    position: relative;
    border-radius: 0.5em 0.5em 0 0;
`;

const CheckRoot = styled.div`
    background-color: ${colours.positive[3]};
    color: ${colours.positive[9]};
    display: flex;
    border-radius: 50%;
    position: absolute;
    top: -0.75rem;
    right: 1rem;
    padding: 0.25rem;
    transform: rotate(5deg);
`;

const CrossRoot = styled.div`
    background-color: ${colours.negative[3]};
    color: ${colours.negative[9]};
    display: flex;
    border-radius: 50%;
    position: absolute;
    top: -0.75rem;
    right: 1rem;
    padding: 0.25rem;
    transform: rotate(5deg);
`;

const CorrectnessIcon = ({ prediction, result, status }) => {
    if (hasFinished(status) && prediction) {
        return prediction === result ? (
            <CheckRoot>
                <CheckIcon />
            </CheckRoot>
        ) : (
            <CrossRoot>
                <ClearIcon />
            </CrossRoot>
        );
    }
    return null;
};

const Pill = props => {
    const {
        homeTeam,
        awayTeam,
        homeScore,
        awayScore,
        status,
        displayedMatchTime,
        id,
        prediction,
        result
    } = props;
    const [error, setError] = useState();
    const onDisabledClick = () =>
        setError(
            error ? null : "The deadline has passed to change this prediction"
        );
    return (
        <MatchContainer>
            <MatchHeader>
                <TimeHeading className="matchTime" data-hj-whitelist>
                    <Link
                        to={`/match/${id}`}
                        data-hj-whitelist
                        title={`Click to see match stats${
                            hasStarted(status) ? "" : " on day of match"
                        }`}
                    >
                        {displayedMatchTime} ⓘ
                    </Link>
                </TimeHeading>
                {error && (
                    <PredictionError title={error}>{error}</PredictionError>
                )}
                <Grow />
                {isInProgress(status) && <LiveIndicator />}
                <CorrectnessIcon {...{ prediction, result, status }} />
            </MatchHeader>
            <SectionContainer className="match">
                <PillSection
                    {...{ ...props, onDisabledClick }}
                    LabelComponent={Left}
                    option="home"
                    topText={teamNames[homeTeam] || homeTeam}
                    bottomText="win"
                    title="Click here to predict a home win (before midnight the day before the match)"
                />

                <PillSection
                    {...{ ...props, onDisabledClick }}
                    LabelComponent={Centre}
                    option="draw"
                    topText={
                        hasStarted(status)
                            ? `${homeScore} - ${awayScore}`
                            : "vs"
                    }
                    bottomText="draw"
                    title="Click here to predict a draw (before midnight the day before the match)"
                />

                <PillSection
                    {...{ ...props, onDisabledClick }}
                    LabelComponent={Right}
                    option="away"
                    topText={teamNames[awayTeam] || awayTeam}
                    bottomText={"win"}
                    title="Click here to predict an away win (before midnight the day before the match)"
                />
            </SectionContainer>
        </MatchContainer>
    );
};

export { Pill };
