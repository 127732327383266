import React from "react";
import { connect } from "react-redux";
import {
    isLoggedIn,
    userPredictions,
    matchesById,
    notPostponedMatchIds
} from "../../store/reducers";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PredictionsForm } from "./PredictionsForm";
import { EditIcon } from "../edit";
import { brightBlue } from "./styles/colours";
import { eqIfLess } from "../../utils/withinBounds";
import { loGet } from "../../utils/alias";
import { isAfter } from "../../utils/date";
import { ShowMoreButton } from "../styles/links";

const Root = styled.div`
    margin-bottom: 4rem;
`;

const EditLinkText = styled.span`
    color: ${brightBlue};
    margin-right: 0.15rem;
    &:hover {
        text-decoration: underline;
    }
`;

const EditLink = styled(Link)`
    font-size: 1rem;
    text-decoration: none;

    &:before {
        content: "\\00B7";
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        text-decoration: none;
    }
`;

let CurrentSummary = ({ isLoggedIn, range }) => {
    return (
        <Root>
            <PredictionsForm range={range} editable={true} />
            <ShowMoreButton to="/edit-predictions" />
        </Root>
    );
};

const mapState = state => {
    const predictions = userPredictions(state);
    const ids = notPostponedMatchIds(state);
    const nextMatchIndex = ids.findIndex(id =>
        isAfter(
            matchesById(state)[id].time,
            new Date(new Date().getTime() - 135 * 60000)
        )
    );
    const startAtIndex = eqIfLess(nextMatchIndex - 2, 0);
    const hasMadePredictions = loGet(predictions, [ids[0], "prediction"]);
    return {
        hasMadePredictions,
        isLoggedIn: isLoggedIn(state),
        range: [startAtIndex, startAtIndex + 5]
    };
};

CurrentSummary = connect(mapState)(CurrentSummary);

export { CurrentSummary };
