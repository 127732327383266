import { matchesById, matchIds, currentUserPredictions } from "../reducers";
import { isAfter } from "../../utils/date";

const selectNextMatch = state => {
    const byId = matchesById(state);
    const ids = matchIds(state);
    const id = ids.find(id => {
        const matchdate = byId[id].time;
        const now = new Date();
        return isAfter(matchdate, now);
    });
    return byId[id];
};

const selectUnfinishedPreviousMatchdayMatch = state => {
    const byId = matchesById(state);
    const ids = matchIds(state);
    const nextMatch = selectNextMatch(state);
    if (!nextMatch) {
        return null;
    }

    const predictions = currentUserPredictions(state);
    const unfinishedPreviousMatchdayMatchId = ids.find((id, index) => {

        const prediction = predictions[id];
        const match = byId[id];

        const isMatchdayBeforeNextMatch = match.matchday < nextMatch.matchday;

        const isAPostponedPastMatch = isMatchdayBeforeNextMatch && match.status === "POSTPONED";

        const isAfterNextMatch = index > ids.indexOf(nextMatch.id);
        const isUnpredicted = !prediction || !prediction.prediction;
        const isOutOfMatchdayOrder =
            isAfterNextMatch && isMatchdayBeforeNextMatch;
        return isUnpredicted && (isOutOfMatchdayOrder || isAPostponedPastMatch);
    });
    return byId[unfinishedPreviousMatchdayMatchId];
};

export const selectInitialMatchdayToEdit = state => {
    const unfinishedPreviousMatchdayMatch = selectUnfinishedPreviousMatchdayMatch(
        state
    );

    if (unfinishedPreviousMatchdayMatch) {
        return unfinishedPreviousMatchdayMatch.matchday;
    }

    const nextMatch = selectNextMatch(state);
    return nextMatch && nextMatch.matchday || 38
};
