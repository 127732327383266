import React from "react";
import { Layout } from "../components/layout/Layout";
import { Leaderboard } from "../components/leaderboard/Leaderboard";
import { CurrentSummary } from "../components/predictions/CurrentSummary";
import { isLoggedIn, predictionsMade } from "../store/reducers";
import { connect } from "react-redux";
import { InputProgress } from "../components/InputProgress";

let Home = ({ hasMadeAllPredictions, isLoggedIn }) => {
    return (
        <Layout>
            {isLoggedIn && (
                <React.Fragment>
                    {!hasMadeAllPredictions && <InputProgress />}
                    <Leaderboard />
                </React.Fragment>
            )}
            <CurrentSummary />
            {isLoggedIn && hasMadeAllPredictions && <InputProgress />}
        </Layout>
    );
};

const mapState = state => ({
    isLoggedIn: isLoggedIn(state),
    hasMadeAllPredictions: predictionsMade(state) === 380
});

Home = connect(mapState)(Home);

export { Home };
