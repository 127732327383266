import { parse, format } from "date-fns";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { teamNames } from "../../utils/football-data-org/teamNames";
import { colours } from "../predictions/styles/colours";

const Root = styled.div`
    width: 900px;
    max-width: 99vw;
    display: inline-grid;
    grid-template-columns: 1fr 4rem 1fr;
    @media only screen and (min-width: 840px) {
        grid-template-columns: 1fr 5rem 1fr;
    }
`;

const PillSection = styled(motion.button)`
    border: 2px solid ${colours.primary[5]};
    background-color: ${colours.white};
    padding: 0.5rem;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: block;
`;

const LeftSection = styled(PillSection)`
    border-radius: 20px 0 0 20px;
`;

const MiddleSection = styled(PillSection)`
    border-left: none;
    border-right: none;
`;

const RightSection = styled(PillSection)`
    border-radius: 0 20px 20px 0;
`;

const Top = styled.div`
    @media only screen and (min-width: 540px) {
        font-size: 1.2rem;
    }
    @media only screen and (min-width: 720px) {
        font-size: 1.7rem;
    }
    font-weight: bold;
    color: ${colours.greys[9]};
`;
const Bottom = styled.div`
    color: ${colours.greys[6]};
`;

export const ShootoutPill = ({
    match,
    onPredictionSubmit,
    currentPrediction
}) => {
    const defaultProps = {
        initial: { backgroundColor: colours.white },
        animate: null,
        transition: { repeat: 5, repeatType: "reverse" }
    };
    const selectedProps = {
        animate: { backgroundColor: colours.primary[3] },
        initial: { backgroundColor: colours.primary[1] }
    };
    const getAnimationProps = result =>
        currentPrediction === result ? selectedProps : defaultProps;
    return (
        <Root>
            <LeftSection
                onClick={onPredictionSubmit("home")}
                {...getAnimationProps("home")}
            >
                <Top>{teamNames[match.homeTeam]}</Top>
                <Bottom>Home win</Bottom>
            </LeftSection>
            <MiddleSection
                onClick={onPredictionSubmit("draw")}
                {...getAnimationProps("draw")}
            >
                <Top>vs</Top>
                <Bottom>Draw</Bottom>
            </MiddleSection>
            <RightSection
                onClick={onPredictionSubmit("away")}
                {...getAnimationProps("away")}
            >
                <Top>{teamNames[match.awayTeam]}</Top>
                <Bottom>Away win</Bottom>
            </RightSection>
        </Root>
    );
};
