import styled from "styled-components";
import React from "react";
import { Logo } from "../components/Logo";

const Background = styled.div`
    background-color: #222;
    height: 100%;
    padding: 20px;
    color: white;
    text-align: center;
`;

const SpinnerBuffer = styled.div`
    height: 40%;
`;

const LoadingPage = () => (
    <Background>
        <SpinnerBuffer />
        <Logo spinDuration={2} />
    </Background>
);

export { LoadingPage };
