import React, { Component } from "react";
import { allLoaded } from "../../store/reducers";
import { connect } from "react-redux";
import styled, { injectGlobal } from "styled-components";
import { LoadingPage } from "../../pages/LoadingPage";
import { mainFontColour } from "../styles/styles";
import { colours } from "../predictions/styles/colours";
import { BigHeader } from "./bigHeader/BigHeader";
import { Header } from "./header/Header";

injectGlobal`
    #root, body, html {
        height: 100%;
    }

    @keyframes App-logo-spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }

    body {
        background-color: ${colours.greys[1]};
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }

`;

const LayoutContainer = styled.div`
    color: ${mainFontColour};
    font-family: "Avenir", "Avenir Next", "Lato", sans-serif;
    padding-bottom: 6rem;
    text-align: center;
`;

const Content = styled.div`
    max-width: 40em;
    width: 95%;
    @media (min-width: 768px) {
        width: 80%;
    }
    margin: 1.5rem auto;
    margin-bottom: 5rem;
    font-size: large;
`;

class Layout extends Component {
    componentDidCatch(error, info) {
        this.props.logError({ stack: error.stack, info });
    }

    render() {
        const { children, allLoaded, bigHeader, contentWrapperStyle } = this.props;
        if (allLoaded) {
            return (
                <LayoutContainer>
                    {bigHeader ? <BigHeader /> : <Header />}
                    <Content id="content" style={contentWrapperStyle}>
                        {children}
                    </Content>
                </LayoutContainer>
            );
        }
        return <LoadingPage />;
    }
}

const mapState = state => ({
    allLoaded: allLoaded(state)
});

const dispatchActions = {
    logError: error => ({
        type: "LOG_ERROR",
        error
    })
};

Layout = connect(
    mapState,
    dispatchActions
)(Layout);

export { Layout };
