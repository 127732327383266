import React, { Fragment } from "react";
import MuiPopover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const Popover = styled(MuiPopover)`
    && {
        pointer-events: none;
        & .MuiPopover-paper {
            max-width: 20rem;
            padding: 0.5rem;
            text-align: center;
        }
    }
`;

const HoverArea = styled.span`
    && {
        cursor: pointer;
    }
`;

export function PopDetails({ icon, details }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handlePopoverOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handlePopoverClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    return (
        <Fragment>
            <HoverArea
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {icon}
            </HoverArea>
            <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>{details}</Typography>
            </Popover>
        </Fragment>
    );
}
