import React from "react";
import { connect } from "react-redux";
import { FieldDiv } from "../../components/FieldDiv";
import { selectUser } from "../../store/reducers";
import { Switch } from "@material-ui/core";
import { Section } from "./styles";

const _NotificationsSection = ({ notificationPermissions, setPermission }) => (
    <Section>
        <h3>Notifications</h3>
        <FieldDiv>
            <label>Reminders:</label>{" "}
            <Switch
                onChange={e => setPermission({ reminders: e.target.checked })}
                checked={
                    notificationPermissions && notificationPermissions.reminders
                }
            />
        </FieldDiv>
        <FieldDiv>
            <label>Important updates:</label>{" "}
            <Switch
                onChange={e => setPermission({ updates: e.target.checked })}
                checked={
                    notificationPermissions && notificationPermissions.updates
                }
            />
        </FieldDiv>
    </Section>
);

const dispatchActions = {
    setPermission: notificationPermissions => ({
        type: "REQUEST_UPDATE_USER",
        details: { notificationPermissions }
    })
};

const mapState = state => ({
    ...selectUser(state)
});

const NotificationsSection = connect(
    mapState,
    dispatchActions
)(_NotificationsSection);

export { NotificationsSection };
