import { all, put, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { history } from "../../history";
import { logError, logLogin, logLogout, logRegister } from "../loggers";
import { postNewUserDetails } from "../postNewUserDetails";
import { firebase } from "../../../utils/alias";
import { isTest } from "../initialiseFirebase";

const loginFirebaseEmailUser = ({ email, password }) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

export const getSearchParamsObj = () => {
    // from: https://stackoverflow.com/questions/8648892/convert-url-parameters-to-a-javascript-object/8649003
    try {
        var search = window.location.search.substring(1);
        return JSON.parse(
            '{"' +
                decodeURI(search)
                    .replace(/"/g, '\\"')
                    .replace(/&/g, '","')
                    .replace(/=/g, '":"') +
                '"}'
        );
    } catch (e) {
        console.log(e);
        return {};
    }
};

const login = function*({ loginDetails }) {
    try {
        yield loginFirebaseEmailUser(loginDetails);
        const { redirectedFrom: redirectTo = "/" } = getSearchParamsObj();
        yield history.push(decodeURIComponent(redirectTo));
        yield logLogin({ email: loginDetails.email });
    } catch (e) {
        yield put({ type: "FORM_FAIL", formError: e });
        yield logError(e);
    }
};
const logoutFirebase = () => firebase.auth().signOut();

const logout = function*() {
    yield logoutFirebase();
    yield history.push("/login");
    yield logLogout();
};

const registerFirebaseEmailUser = ({ email, password }) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

const sanitizeUser = ({ displayName, email, emailVerified, uid }) => ({
    displayName,
    email,
    emailVerified,
    uid
});

const isDisplayNameValidCharacters = ({ displayName }) => {
    const lettersAndNumbersRegex = /[^A-Za-z0-9]+/g;
    const onlyContainsLettersAndNumbers = !lettersAndNumbersRegex.test(
        displayName
    );
    return onlyContainsLettersAndNumbers;
};

const register = function*({ loginDetails }) {
    if (!loginDetails.displayName) {
        return yield yield put({
            type: "FORM_FAIL",
            formError: {
                code: "auth/display-name-required",
                message: "Please enter a display name"
            }
        });
    }
    if (!isDisplayNameValidCharacters(loginDetails)) {
        return yield yield put({
            type: "FORM_FAIL",
            formError: {
                code: "auth/display-name-invalid-characters",
                message: "Display name must only contain letters and numbers"
            }
        });
    }
    try {
        yield registerFirebaseEmailUser(loginDetails);
        yield history.push("/edit-predictions");
        let firebaseUser = firebase.auth().currentUser;
        yield firebaseUser.updateProfile({
            displayName: loginDetails.displayName
        });
        const user = sanitizeUser(firebaseUser);
        yield put({ type: "UPDATE_USER", user });
        if (!isTest) {
            try {
                yield firebaseUser.sendEmailVerification();
            } catch (e) {
                yield logError(e);
            }
        }

        yield postNewUserDetails({
            ...user,
            points: 0,
            joinedAt: new Date(),
            finishedPredictions: 0,
            latestMatchday: 0,
            latestMatchdayFinished: 0,
            predictionsMade: 0,
            notificationPermissions: {
                reminders: true,
                updates: true
            }
        });
        yield logRegister({
            email: loginDetails.email,
            displayName: loginDetails.displayName
        });
    } catch (e) {
        yield put({ type: "FORM_FAIL", formError: e });
        yield logError(e);
    }
};

const resetPassword = function*({ email }) {
    try {
        if (!isTest) {
            yield firebase.auth().sendPasswordResetEmail(email);
        }
    } catch (e) {
        yield logError(e);
    }
};

const authStateChange = () =>
    eventChannel(emit =>
        firebase.auth().onAuthStateChanged(user => emit({ user }))
    );

const handleAuthStateChange = function*({ user }) {
    if (user) {
        yield put({ type: "USER_FOUND", user: sanitizeUser(user) });
    } else {
        yield put({ type: "NO_USER_FOUND" });
    }
};

const watchAuthState = function*() {
    yield takeEvery(authStateChange(), handleAuthStateChange);
};

const authentication = function*() {
    yield all([
        takeEvery("LOGIN", login),
        takeEvery("LOGOUT", logout),
        takeEvery("REGISTER", register),
        takeEvery("RESET_PASSWORD", resetPassword),
        takeEvery("REQUEST_UPDATE_USER", function*({ details }) {
            yield postNewUserDetails(details);
        }),
        watchAuthState()
    ]);
};

export { authentication };
