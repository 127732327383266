import {
    blueHoverColour,
    brightBlue,
    liveMatchColour,
    colours
} from "./colours";
import Colour from "color";
import styled, { css } from "styled-components";
import {
    hasStarted,
    isInProgress
} from "../../../utils/football-data-org/statuses";

export const MatchContainer = styled.div`
    box-shadow: 0.1rem 0.1rem 0.2rem ${colours.greys[5]},
        0.5rem 0.5rem 0.5rem ${colours.greys[3]};
    border-radius: 0.5em;
    margin: 1.5rem 0;
`;

export const SectionContainer = styled.div`
    display: inline-grid;
    grid-template-columns: 1fr 4em 1fr;
    width: 100%;
    border-radius: 0 0 0.5em 0.5em;
    overflow: hidden;
`;

const greenTextPulsing = css`
    @keyframes pulse {
        100% {
            color: ${liveMatchColour};
        }
    }
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
`;

const Label = styled.label`
    background-color: white;
    input:checked + & {
        ${({ frequency }) =>
            !frequency
                ? css`
                      background-color: ${colours.primary[1]};
                      color: ${colours.primary[9]};
                  `
                : ""};
    }
    ${({ frequency }) =>
        frequency
            ? css`
                  background-color: ${Colour(brightBlue)
                      .alpha(frequency)
                      .toString()};
              `
            : ""} box-sizing: border-box;
    ${({ disabled }) =>
        !disabled &&
        css`
            &&:hover {
                background-color: ${blueHoverColour};
                color: ${colours.primary[9]};
            }
        `} ${({ disabled }) =>
        !disabled && "cursor: pointer;"}
    display: inline-block;
    height: 100%;
    padding: 0.5em;
    width: 100%;
    ${({ isCorrect, status }) =>
        isCorrect && hasStarted(status)
            ? `
        
    `
            : ""} ${({ status, isCorrect }) =>
        isInProgress(status) && isCorrect && greenTextPulsing};
`;

const Side = styled(Label)``;

const Left = styled(Side)``;

const Centre = styled(Label)`
    border-left: solid 1px ${colours.greys[2]};
    border-right: solid 1px ${colours.greys[2]};
`;

const Right = styled(Side)``;

const Top = styled.div``;

const Bottom = styled.div`
    font-size: 0.9rem;
    opacity: ${({ disabled, isCorrect, frequencies }) => (!disabled || isCorrect || frequencies ? 0.5 : 0)};
    margin-top: 0.2rem;
`;

const Input = styled.input``;

export { Bottom, Centre, Input, Left, Right, Top };
