import React, { Component } from "react";
import { connect } from "react-redux";
import { allLoaded, isLoggedIn, userLoaded } from "../../store/reducers/index";
import { history } from "../../store/history";
import { LoadingPage } from "../../pages/LoadingPage";

const mapState = state => ({
    isLoggedIn: isLoggedIn(state),
    userLoaded: userLoaded(state),
    allLoaded: allLoaded(state)
});

const connectRedirectGuard = connect(mapState);

const createRedirectGuard = ({
    blockedLoggedInStatus,
    redirectTo
}) => ProtectedComponent => {
    class RedirectGuard extends Component {
        redirectIfNoPermission() {
            if (
                this.props.userLoaded &&
                this.props.isLoggedIn === blockedLoggedInStatus
            ) {
                this.redirect();
            }
        }

        redirect() {
            const redirectedFrom = encodeURIComponent(
                `${window.location.pathname}${window.location.search}`
            );
            history.replace(`${redirectTo}?redirectedFrom=${redirectedFrom}`);
        }

        componentDidMount() {
            this.redirectIfNoPermission();
        }

        componentDidUpdate() {
            this.redirectIfNoPermission();
        }

        render() {
            if (
                this.props.allLoaded &&
                this.props.isLoggedIn !== blockedLoggedInStatus
            ) {
                return <ProtectedComponent {...this.props} />;
            }
            return <LoadingPage />;
        }
    }
    return connectRedirectGuard(RedirectGuard);
};

const loggedOutOnly = createRedirectGuard({
    blockedLoggedInStatus: true,
    redirectTo: "/"
});

const loggedInOnly = createRedirectGuard({
    blockedLoggedInStatus: false,
    redirectTo: "/login"
});

const loggedInOnlyNewUser = createRedirectGuard({
    blockedLoggedInStatus: false,
    redirectTo: "/register"
});

export { loggedOutOnly, loggedInOnly, loggedInOnlyNewUser };
