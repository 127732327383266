import get from "lodash.get";
import orderBy from "lodash.orderby";
import { selectUid, userLeaderboards } from ".";

const addCustomPositions = (scoreboard, userIds) => {
    userIds = userIds.filter(id => scoreboard[id].displayName);
    userIds = orderBy(
        userIds,
        [
            id => scoreboard[id].points,
            id => scoreboard[id].pointsBreakdown.away,
            id => scoreboard[id].pointsBreakdown.draw,
            id => scoreboard[id].predictionsMade,
            id => scoreboard[id].displayName.toLowerCase()
        ],
        ["desc", "desc", "desc", "desc", "asc"]
    );
    let leaderboard;
    let previousUser = {};
    userIds.forEach((id, index) => {
        let explicitPosition;
        let user = scoreboard[id];
        if (
            user.points === previousUser.points &&
            user.pointsBreakdown.away === previousUser.pointsBreakdown.away &&
            user.pointsBreakdown.draw === previousUser.pointsBreakdown.draw
        ) {
            explicitPosition = previousUser.explicitPosition;
        } else {
            explicitPosition = index + 1;
        }
        user = {
            ...user,
            explicitPosition,
            implicitPosition: index + 1
        };
        leaderboard = {
            ...leaderboard,
            [id]: user
        };
        previousUser = user;
    });
    return leaderboard;
};

export const leaderboard = selectedLeaderboard => state => {
    if (!selectedLeaderboard || selectedLeaderboard === "1") {
        return state.leaderboard;
    }
    return addCustomPositions(
        state.leaderboard,
        leaderboardUserIds(selectedLeaderboard)(state)
    );
};

export const leaderboards = state => state.leaderboards;
const leaderboardConfig = id => state => leaderboards(state)[id];
export const leaderboardUserIds = id => state =>
    Object.values(leaderboardConfig(id)(state).userIds);

export const availableLeaderboards = state => {
    const ids = userLeaderboards(state);
    if (!ids) return [];
    const allLeaderboards = leaderboards(state);
    return ids.map(id => ({ id, ...allLeaderboards[id] }));
};

export const selectOrderedLeaderboard = selectedLeaderboard => state => {
    const fullLeaderboard = state.orderedLeaderboard;

    if (!selectedLeaderboard || selectedLeaderboard === "1")
        return fullLeaderboard;

    const selectedLeaderboardUserIds = leaderboardUserIds(selectedLeaderboard)(
        state
    );
    const filteredUsers = fullLeaderboard.filter(id =>
        selectedLeaderboardUserIds.includes(id)
    );
    return filteredUsers;
};

const getStartIndex = (currentUserIndex, length) => {
    if (currentUserIndex < 1) {
        return 0;
    }
    if (currentUserIndex > length - 2) {
        return length - 3;
    }
    return currentUserIndex - 1;
};

export const selectSumarisedLeaderboard = state => {
    const currentUserId = selectUid(state);
    const users = selectOrderedLeaderboard()(state);
    const currentUserIndex = users.indexOf(currentUserId);
    const startAtIndex = getStartIndex(currentUserIndex, users.length);
    return users.slice(startAtIndex, startAtIndex + 3);
};

export const userAchievements = displayName => state => {
    const users = leaderboard(state);
    const uid = Object.keys(users).find(id => {
        return get(users, [id, "displayName"]) === displayName;
    });
    const achievements = get(users, [uid, "achievements"]);
    if (achievements) {
        return Object.values(achievements);
    }
};
