import { all, fork } from "redux-saga/effects";
import { initialiseFirebase } from "./initialiseFirebase";
import { authentication } from "./authentication";
import { watchSubmitPredictions } from "./submitPredictions";
import { watchUserThenListenPredictions } from "./firebaseDataSync/predictions";
import { logError, watchLogError } from "./loggers";
import { watchUserThenListenOnLeaderboard } from "./firebaseDataSync/leaderboard";
import { listenOnMatchesChange } from "./firebaseDataSync/matches";
import { updateMatchData } from "./updateMatchData";
import { watchUserFoundThen } from "./shared";
import { initializeNotifications } from "./notifications/initializeNotifications";
import { watchNotificationRequest } from "./notifications/request";
import { watchUserThenListenPermissions } from "./firebaseDataSync/permissions";
import { watchUserThenListenFrequencies } from "./firebaseDataSync/frequencies";
import { watchUserThenListencurrentUser } from "./firebaseDataSync/currentUser";
import { watchUserThenListenOnLeaderboards } from "./firebaseDataSync/leaderboards";

const rootSaga = function*() {
    try {
        yield watchLogError;

        yield initialiseFirebase();

        yield fork(all, [
            authentication(),
            listenOnMatchesChange(),
            updateMatchData(),
            watchSubmitPredictions,
            watchUserThenListenFrequencies,
            watchUserThenListenPermissions,
            watchUserThenListenPredictions,
            watchUserThenListenOnLeaderboard,
            watchUserThenListenOnLeaderboards,
            watchUserThenListencurrentUser,
            watchUserFoundThen(initializeNotifications),
            watchNotificationRequest
        ]);
    } catch (e) {
        console.error('happened in rootSaga')
        yield logError(e);
        window.location.reload(); // TODO: redirect to error page?
    }
};

export { rootSaga };
