import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../components/layout/Layout";

const PageNotFound = () => (
    <Layout bigHeader={true}>
        <h2>
            Page not found, go <Link to="/">Home</Link>
        </h2>
    </Layout>
);

export { PageNotFound };
