import React, { useState } from "react";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { Button } from "../styles/styles";
import { colours } from "../predictions/styles/colours";

const Root = styled.div`
    padding-left: 12px;
    text-align: center;
`;

const ModalRoot = styled.div`
    min-height: 200px;
    width: 800px;
    max-width: 100%;
`;

const IntroMessage = styled.div`
    margin-bottom: 1rem;
`;

const Icon = styled.span`
    margin-right: 0.5rem;
    font-size: 2rem;
    vertical-align: middle;
`;

const DirectionExplanation = styled.li`
    margin-bottom: 0.2rem;
    list-style: none;
`;

const Image = styled.img`
    width: 100%;
    border-radius: 20px;
    box-shadow: 0.1rem 0.1rem 0.3rem 0.05rem ${colours.greys[5]},
        0.2rem 0.2rem 0.7rem 0.2rem ${colours.greys[3]};

    padding-top: 1rem;
    padding-bottom: -3rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2rem;
`;

export const ShootoutIntro = () => {
    const [showModal, setShowModal] = useState(
        !window.localStorage.getItem("hasSeenShootoutIntro")
    );
    return (
        <Root>
            {showModal && (
                <Dialog
                    open={true}
                    onClose={() => setShowModal(false)}
                    PaperProps={{
                        style: {
                            margin: 8,
                            padding: "1.5rem",
                            maxHeight: "100%"
                        }
                    }}
                >
                    <ModalRoot>
                        <h2>New feature!</h2>
                        <IntroMessage>
                            Swipe the ball to make your prediction.
                        </IntroMessage>
                        <ul>
                            <DirectionExplanation>
                                <Icon>➡️</Icon> Goal on the right for a home win
                            </DirectionExplanation>
                            <DirectionExplanation>
                                <Icon>⬅️</Icon> Goal on the left for an away win
                            </DirectionExplanation>
                            <DirectionExplanation>
                                <Icon>⬇️</Icon> Down for a draw
                            </DirectionExplanation>
                        </ul>
                        <Image src="https://user-images.githubusercontent.com/10728145/128610646-eb38ca5f-f8a8-4552-b0b0-9647d9f99f41.gif" />
                        <Actions>
                            <Button
                                onClick={() => {
                                    setShowModal(false);
                                    window.localStorage.setItem(
                                        "hasSeenShootoutIntro",
                                        true
                                    );
                                }}
                            >
                                OK, I get it!
                            </Button>
                        </Actions>
                    </ModalRoot>
                </Dialog>
            )}
        </Root>
    );
};
