import React, { useRef, useState } from "react";
import { selectDisplayName } from "../../../../store/reducers";
import { connect, useSelector } from "react-redux";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { Link } from "react-router-dom";

const _MenuUserActions = ({ logout }) => {
    const buttonRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const displayName = useSelector(selectDisplayName);
    return (
        <>
            <IconButton
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={() => setIsOpen(true)}
                ref={buttonRef}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                anchorEl={buttonRef.current}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <MenuItem data-hj-suppress id="displayName" disabled>
                    {displayName}
                </MenuItem>
                <MenuItem to="/account" component={Link}>
                    My account
                </MenuItem>
                <MenuItem to={`/p/${displayName}`} component={Link}>
                    My profile
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </>
    );
};

const dispatchActions = {
    logout: () => ({
        type: "LOGOUT"
    })
};

const MenuUserActions = connect(
    null,
    dispatchActions
)(_MenuUserActions);

export { MenuUserActions };
