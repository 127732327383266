import React from "react";
import { Layout } from "../components/layout/Layout";
import { EditPredictionsByMatchday } from "../components/editPredictions/EditPredictionsByMatchday";

const EditPredictionsPage = () => (
    <Layout>
        <EditPredictionsByMatchday />
    </Layout>
);

export { EditPredictionsPage as EditPredictions };
