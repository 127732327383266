import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    areNotificationsAvailable,
    hasNotificationPermission,
    isNotificationBlocked
} from "../../../store/sagas/notifications/request";
import { NotificationRequest } from "./NotificationRequest";
import {
    allLoaded,
    appNotifications,
    isLoggedIn
} from "../../../store/reducers";
import { compose, withState } from "recompose";
import { Alert } from "./styles";

const AlertsContainer = styled.div`
    bottom: 1rem;
    margin-left: 1rem;
    max-width: 25rem;
    position: fixed;
    right: 1rem;
    z-index: 99;
`;

let Alerts = ({
    appNotifications,
    shouldRequestNotifications,
    setClicked,
    hasClicked
}) => (
    <AlertsContainer>
        {Object.keys(appNotifications).map(id => {
            const notification = appNotifications[id];
            return <Alert key={notification.id}>{notification.message}</Alert>;
        })}
        {shouldRequestNotifications &&
            !hasClicked && <NotificationRequest setClicked={setClicked} />}
    </AlertsContainer>
);

const mapState = state => ({
    appNotifications: appNotifications(state),
    shouldRequestNotifications:
        allLoaded(state) &&
        isLoggedIn(state) &&
        areNotificationsAvailable() &&
        !hasNotificationPermission() &&
        !isNotificationBlocked()
});

const dispatchActions = {};
const connectRedux = connect(
    mapState,
    dispatchActions
);

Alerts = compose(
    connectRedux,
    withState("hasClicked", "setClicked", false)
)(Alerts);

export { Alerts };
