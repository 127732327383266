import React, { useState } from "react";
import { Layout } from "../components/layout/Layout";
import { Link } from "react-router-dom";
import { ForgottenPasswordForm } from "../components/forgottenPassword/ForgottenPasswordForm";

const ForgottenPassword = () => {
    const [submitted, setSubmitted] = useState(false);
    const submit = () => setSubmitted(true);
    if (submitted) {
        return (
            <Layout bigHeader={true}>
                <h1>Thanks</h1>
                <p>
                    If an account exists with this email address, you should
                    receive an email with instructions to reset your password.
                </p>
                <div>
                    <Link to="/login">Login</Link>
                </div>
            </Layout>
        );
    }
    return <ForgottenPasswordForm submit={submit} />;
};

export { ForgottenPassword };
