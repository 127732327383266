import React, { Fragment } from "react";
import { displayName, isLoggedIn } from "../../../../store/reducers";
import { connect } from "react-redux";
import { HeaderLink, HeaderLinkText, LogoutButton } from "../styles";

const _AppBarUserActions = ({ displayName, logout }) => (
    <Fragment>
        <HeaderLink to={"/account"} id="displayName">
            <HeaderLinkText data-hj-suppress>{displayName}</HeaderLinkText>
        </HeaderLink>
        <LogoutButton onClick={logout}>Logout</LogoutButton>
    </Fragment>
);

const mapState = state => ({
    isLoggedIn: isLoggedIn(state),
    displayName: displayName(state)
});

const dispatchActions = {
    logout: () => ({
        type: "LOGOUT"
    })
};

const AppBarUserActions = connect(
    mapState,
    dispatchActions
)(_AppBarUserActions);

export { AppBarUserActions };
