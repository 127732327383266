import { firebase } from "../../utils/alias";
import { delay } from "redux-saga";
import { logError } from "./loggers";

const updateMatchData = function*() {
    const updateMatches = firebase.functions().httpsCallable("updateMatches");
    while (true) {
        try {
            yield updateMatches();
        } catch (e) {
            console.error('happened in updateMatchData')
            yield logError(e);
        }
        const MS_IN_MINUTE = 1000 * 60;
        yield delay(0.5 * MS_IN_MINUTE);
    }
};

export { updateMatchData };
