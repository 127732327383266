import React, { Fragment } from "react";
import { connect } from "react-redux";
import { selectUid } from "../../store/reducers/index";
import {
    PositionTd,
    Table,
    Td,
    Th,
    Tr,
    TableWrapper,
    UsernameTd
} from "./styles";
import numeral from "numeral";
import { styled } from "../../utils/alias";
import { CompletedPredictionsBadge } from "./CompletedPredictionsBadge";
import { PlainLink, ShowMoreButton } from "../styles/links";
import {
    leaderboard,
    selectOrderedLeaderboard,
    selectSumarisedLeaderboard
} from "../../store/reducers/leaderboard";

const Denom = styled.span`
    font-size: 0.8rem;
`;

const Latest = styled.span`
    margin-left: 0.5rem;
`;

let Leaderboard = ({ curentUserId, leagueTableIds, users, withDetails }) => {
    return (
        <TableWrapper {...{ withDetails }}>
            <div>
                <Table id="leaderboard" data-hj-whitelist>
                    <thead>
                        <tr>
                            <Th />
                            <Th />
                            <Th title="Points from the latest or current 'match day' (/ number of predictions where the match is completed from same match day) – (Match day is a set of 10 matches where each team plays, usually happens over a weekend)">
                                Latest
                            </Th>
                            <Th title="Total points this season – 1 point per correct prediction (% correct)">
                                Total
                            </Th>
                            {withDetails && (
                                <Fragment>
                                    <Th title="Away – points where the guess was an away win">
                                        (a)
                                    </Th>
                                    <Th title="Draw – points where the guess was a draw">
                                        (d)
                                    </Th>
                                    <Th title="Home – points where the guess was a home win">
                                        (h)
                                    </Th>
                                    <Th title="Number of predictions made this season">
                                        Predictions
                                    </Th>
                                </Fragment>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {leagueTableIds.map(id => {
                            const isCurrentUser = id === curentUserId;
                            const user = users[id];
                            if (!isCurrentUser && !user.predictionsMade) {
                                return null;
                            }
                            return (
                                <Tr key={id} data-user-id={id}>
                                    <PositionTd
                                        isCurrentUser={isCurrentUser}
                                        data-hj-whitelist
                                    >
                                        {user.explicitPosition}
                                    </PositionTd>
                                    <UsernameTd isCurrentUser={isCurrentUser}>
                                        <PlainLink
                                            to={`/p/${user.displayName}`}
                                        >
                                            <span data-hj-suppress>
                                                {user.displayName}
                                            </span>
                                        </PlainLink>{" "}
                                        <CompletedPredictionsBadge {...user} />
                                    </UsernameTd>
                                    <Td isCurrentUser={isCurrentUser}>
                                        {user.latestMatchdayFinished ? (
                                            <Latest data-hj-whitelist>
                                                {user.latestMatchday}
                                                <Denom data-hj-whitelist>
                                                    /
                                                    {
                                                        user.latestMatchdayFinished
                                                    }
                                                </Denom>
                                            </Latest>
                                        ) : (
                                            <Latest data-hj-whitelist>0</Latest>
                                        )}
                                    </Td>
                                    <Td
                                        isCurrentUser={isCurrentUser}
                                        data-hj-whitelist
                                    >
                                        {user.points || 0}
                                        {withDetails &&
                                            ` (${numeral(
                                                user.finishedPredictions
                                                    ? user.points /
                                                      user.finishedPredictions
                                                    : 0
                                            ).format("0[.]0%")})`}
                                    </Td>
                                    {withDetails && (
                                        <Fragment>
                                            <Td
                                                isCurrentUser={isCurrentUser}
                                                data-hj-whitelist
                                            >
                                                {user.pointsBreakdown.away || 0}
                                            </Td>
                                            <Td
                                                isCurrentUser={isCurrentUser}
                                                data-hj-whitelist
                                            >
                                                {user.pointsBreakdown.draw || 0}
                                            </Td>
                                            <Td
                                                isCurrentUser={isCurrentUser}
                                                data-hj-whitelist
                                            >
                                                {user.pointsBreakdown.home || 0}
                                            </Td>
                                            <Td
                                                isCurrentUser={isCurrentUser}
                                                data-hj-whitelist
                                            >
                                                {user.predictionsMade || 0}
                                            </Td>
                                        </Fragment>
                                    )}
                                </Tr>
                            );
                        })}
                    </tbody>
                </Table>
                {!withDetails && (
                    <ShowMoreButton data-cy="table-details" to="/details" />
                )}
            </div>
        </TableWrapper>
    );
};

const mapState = (state, { withDetails, selectedLeaderboard }) => {
    const users = leaderboard(selectedLeaderboard)(state);
    const userIds = withDetails
        ? selectOrderedLeaderboard(selectedLeaderboard)(state)
        : selectSumarisedLeaderboard(state);
    return {
        users,
        leagueTableIds: userIds,
        curentUserId: selectUid(state)
    };
};

Leaderboard = connect(mapState)(Leaderboard);

export { Leaderboard };
