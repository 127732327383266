import { Link } from "react-router-dom";
import styled from "styled-components";
import { colours } from "../../predictions/styles/colours";

export const HeaderLink = styled(Link)`
    color: ${colours.primary[9]};
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    &:hover {
        color: ${colours.primary[9]} !important;
        background-color: ${colours.primary[1]};
        text-decoration: none;
    }
    @media only screen and (max-width: 540px) {
        padding: 0 0.5rem;
    }
    @media only screen and (max-width: 420px) {
        padding: 0 0.25rem;
    }
`;

export const HeaderLinkText = styled.div``;

export const LogoutButton = styled.button`
    display: block;
    background-color: white;
    border: none;
    color: ${colours.primary[9]};
    cursor: pointer;
    font-size: 1rem;
    height: 100%;
    outline: none !important;
    padding: 0 1rem;

    &:hover {
        background-color: ${colours.primary[1]};
    }
    @media only screen and (max-width: 540px) {
        padding: 0 0.5rem;
    }
    @media only screen and (max-width: 420px) {
        padding: 0 0.25rem;
    }
`;
