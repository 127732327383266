import { BigButton, mainFontColour } from "../styles/styles";
import styled from "styled-components";
import { mediumTextColour } from "../predictions/styles/colours";

export const PaginateGrid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    max-width: 40em;
    width: 95%;
    @media (min-width: 768px) {
        width: 80%;
    }
    margin: 0 auto;
`;

export const Left = styled.div`
    display: inline-block;
    margin-right: 0.5rem;
    text-align: left;
`;

export const Middle = styled.div`
    display: inline-block;
`;

export const ShowOnDeskTop = styled.span`
    @media (max-width: 479px) {
        display: none;
    }
`;

export const ShowOnMobile = styled.span`
    @media (min-width: 480px) {
        display: none;
    }
`;

export const Right = styled.div`
    display: inline-block;
    margin-left: 0.5rem;
    text-align: right;
`;

export const SmallButton = styled(BigButton)`
    color: ${mediumTextColour};
    width: 4rem;
    padding: 0.5rem;
    margin: 0.5rem;
    margin-top: 1rem;
    &:hover {
        color: ${mainFontColour};
    }
`;

export const ExpandingBigButton = styled(BigButton)`
    max-width: 15rem;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
`;
