import React, { Component } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";

import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { PageNotFound } from "./pages/PageNotFound";
import { Provider } from "react-redux";
import { createAppStore } from "./store";
import { history } from "./store/history";
import { EditPredictions } from "./pages/EditPredictions";
import { ScrollToTop } from "./components/ScrollToTop";
import {
    loggedInOnly,
    loggedInOnlyNewUser,
    loggedOutOnly
} from "./components/hocs/loggedInGuards";
import { Home } from "./pages/Home";
import { DetailedLeaderboard } from "./pages/DetailedLeaderboard";
import { Match } from "./pages/Match";
import { adminOnly } from "./components/hocs/adminOnly";
import { Send } from "./pages/Send";
import { ForgottenPassword } from "./pages/ForgottenPassword";
import { Account } from "./pages/Account";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Profile } from "./pages/Profile";
import { AppUtilities } from "./components/appUtilities/AppUtilities";
import { CreateLeaderboard } from "./pages/CreateLeaderboard";
import { JoinLeaderboard } from "./pages/JoinLeaderboard";
import { PredictionsShootout } from "./pages/PredictionsShootout";

class App extends Component {
    render() {
        return (
            <Provider store={createAppStore()}>
                <Router history={history}>
                    <ScrollToTop>
                        <Switch>
                            <Redirect from="/index.html" to="/" />
                            <Route
                                exact
                                path="/login"
                                component={loggedOutOnly(Login)}
                            />
                            <Route
                                exact
                                path="/register"
                                component={loggedOutOnly(Register)}
                            />
                            <Route
                                exact
                                path="/forgotten-password"
                                component={loggedOutOnly(ForgottenPassword)}
                            />
                            <Route
                                exact
                                path="/account"
                                component={loggedInOnly(Account)}
                            />
                            <Route
                                exact
                                path="/create-leaderboard"
                                component={loggedInOnly(CreateLeaderboard)}
                            />
                            <Route
                                exact
                                path="/join/:id"
                                component={loggedInOnlyNewUser(JoinLeaderboard)}
                            />
                            <Route
                                exact
                                path="/edit-predictions"
                                component={loggedInOnly(EditPredictions)}
                            />
                            <Route
                                exact
                                path="/shootout"
                                component={loggedInOnly(PredictionsShootout)}
                            />
                            <Route
                                exact
                                path="/p/:displayName"
                                component={loggedInOnly(Profile)}
                            />
                            <Route exact path="/" component={Home} />
                            <Route
                                exact
                                path="/privacy-policy"
                                component={PrivacyPolicy}
                            />
                            <Route
                                exact
                                path="/match/:id"
                                component={loggedInOnly(Match)}
                            />
                            <Route
                                exact
                                path="/details"
                                component={loggedInOnly(DetailedLeaderboard)}
                            />
                            <Route
                                exact
                                path="/send"
                                component={adminOnly(Send)}
                            />
                            <Route component={PageNotFound} />
                        </Switch>
                        <AppUtilities />
                    </ScrollToTop>
                </Router>
            </Provider>
        );
    }
}

export default App;
