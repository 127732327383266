import React from "react";
import { connect } from "react-redux";
import { Layout } from "../components/layout/Layout";
import { Error, FieldDiv } from "../components/FieldDiv";
import { formErrorMessage } from "../store/reducers";
import { BigButton, Input } from "../components/styles/styles";
import { Form } from "../components/Form";
import { Link } from "react-router-dom";

const _Register = ({ formErrorMessage, register }) => (
    <Layout bigHeader={true}>
        <Form
            onSubmit={e => {
                e.preventDefault();
                register({
                    email: e.target.email.value,
                    displayName: e.target.displayName.value,
                    password: e.target.password.value
                });
            }}
        >
            <FieldDiv>
                <Input placeholder="User name" name="displayName" />
            </FieldDiv>
            <FieldDiv>
                <Input type="email" placeholder="Email" name="email" />
            </FieldDiv>
            <FieldDiv>
                <Input type="password" placeholder="Password" name="password" />
            </FieldDiv>
            {formErrorMessage && (
                <FieldDiv>
                    <Error>{formErrorMessage}</Error>
                </FieldDiv>
            )}
            <FieldDiv>
                <BigButton>Register</BigButton>
            </FieldDiv>
            <FieldDiv style={{ marginTop: "4rem" }}>
                Have an account?{" "}
                <Link to={`/login${window.location.search}`}>Login</Link>
            </FieldDiv>
        </Form>
    </Layout>
);

const dispatchActions = {
    register: loginDetails => ({
        type: "REGISTER",
        loginDetails
    })
};

const mapState = state => ({
    formErrorMessage: formErrorMessage(state)
});

const Register = connect(
    mapState,
    dispatchActions
)(_Register);

export { Register };
