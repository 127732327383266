import styled, { css } from "styled-components";
import { brightBlue } from "../predictions/styles/colours";

export const TableWrapper = styled.div`
    ${({ withDetails }) =>
        !withDetails
            ? css`
                  display: flex;
                  align-items: center;
                  flex-direction: column;
              `
            : ""};
`;

const Table = styled.table`
    display: block;
    border-collapse: collapse;
    margin: 0;
    min-width: 50%;
    text-align: left;
    overflow-x: auto;
`;

const Td = styled.td`
    opacity: 0.7;
    white-space: nowrap;
    padding: 1rem;
    ${({ isCurrentUser }) => isCurrentUser && `color: ${brightBlue}`};
`;

const PositionTd = styled(Td)`
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
`;

export const UsernameTd = styled(Td)`
    opacity: 1;
`;

const Th = styled.th`
    color: rgba(0, 0, 0, 0.3);
    font-weight: 400;
    padding: 0 1rem;
`;

const Tr = styled.tr`
    overflow: hidden;
    border-radius: 0.25rem;

    &:hover {
        background-color: rgba(0, 130, 245, 0.2);
        color: ${brightBlue};
    }

    &:active {
        background-color: rgba(0, 130, 245, 0.3);
        color: ${brightBlue};
    }
    & td:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
    }
    & td:last-child {
        border-radius: 0 0.5rem 0.5rem 0;
    }
`;

export { PositionTd, Table, Td, Th, Tr };
