import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colours } from "../../predictions/styles/colours";
import { Dialog } from "@material-ui/core";
import { Button } from "../../styles/styles";

const Root = styled.div`
    padding-left: 12px;
    text-align: center;
`;

const ModalRoot = styled.div`
    min-height: 200px;
    width: 400px;
    max-width: 100%;
`;

const LinkMessage = styled.div`
    font-weight: bold;
    margin-bottom: 1rem;
`;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Link = styled.input.attrs({ readOnly: true })`
    background-color: ${colours.greys[2]};
    margin-bottom: 1rem;
    border-radius: 2px;
    border: solid 1px ${colours.greys[6]};
    color: ${colours.greys[7]};
    display: inline-block;
    padding: 12px;
    max-width: 100%;
    width: 300px;
`;

const CopiedMessage = styled.div`
    color: ${colours.greys[7]};
`;

const shareUrl = leaderboardId =>
    `https://predictable.xyz/join/${leaderboardId}`;

const copyToClipboard = text => navigator.clipboard.writeText(text);

const ShareModalContent = ({ leaderboardId }) => {
    const [isCopied, setIsCopied] = useState(false);
    const url = shareUrl(leaderboardId);
    useEffect(
        () => {
            if (!isCopied) {
                copyToClipboard(url);
                setTimeout(() => setIsCopied(true), 500);
            }
        },
        [isCopied]
    );
    return (
        <ModalRoot>
            <LinkMessage>Invite your friends to join at</LinkMessage>
            <Link
                onClick={e => {
                    setIsCopied(false);
                    e.target.select();
                }}
                value={url}
            />
            <CopiedMessage>
                {isCopied ? "✓ copied to clipboard" : "⊚ copying..."}
            </CopiedMessage>
        </ModalRoot>
    );
};

export const ShareBoardInvite = ({ leaderboardId }) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <Root>
            <Button onClick={() => setShowModal(true)}>Invite</Button>
            {showModal && (
                <Dialog
                    open={true}
                    onClose={() => setShowModal(false)}
                    PaperProps={{ style: { margin: 8, padding: "1.5rem" } }}
                >
                    <ShareModalContent {...{ leaderboardId }} />
                    <Actions>
                        <Button onClick={() => setShowModal(false)}>
                            Done
                        </Button>
                    </Actions>
                </Dialog>
            )}
        </Root>
    );
};
