import { firebase } from "../../utils/alias";

export const isTest = process.env.REACT_APP_ENV === "test";
const isLocalProd = process.env.REACT_APP_ENV === "production";

let config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
if (isTest) {
    config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG_TEST);
} else if (isLocalProd) {
    config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG_PROD);
}

const initialiseFirebase = function*() {
    yield firebase.initializeApp(config);

    if (process.env.NODE_ENV === "production") {
        firebase.performance();
        firebase.analytics();
    }
};

export { initialiseFirebase };
