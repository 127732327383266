import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { useSelector } from "react-redux";
import { isLoggedIn as selectIsLoggedIn } from "../../../../store/reducers";
import { AppBarUserActions } from "./AppBarUserActions";
import { LoggedOutUserActions } from "./LoggedOutUserActions";
import { MenuUserActions } from "./MenuUserActions";

export const UserActions = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isMobile = useMediaQuery("(max-width:1080px)");

    if (!isLoggedIn) {
        return <LoggedOutUserActions />;
    }

    return isMobile ? <MenuUserActions /> : <AppBarUserActions />;
};
