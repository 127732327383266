import { parse, format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colours } from "../predictions/styles/colours";
import { ShootoutPill } from "./ShootoutPill";

const Root = styled.div`
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
`;

const Header = styled.div`
    display: flex;
`;

const BackLink = styled(Link)`
    border: 2px solid ${colours.primary[3]};
    border-radius: 16px;
    padding: 0 0.5rem;
`;

const DateHeading = styled.h4`
    font-weight: normal;
    margin-top: 0.5rem;
`;

export const MatchDetails = ({
    match,
    onPredictionSubmit,
    currentPrediction
}) => {
    return (
        <Root>
            <Header>
                <BackLink to="/edit-predictions">↩ review predictions</BackLink>
            </Header>
            <DateHeading>
                Matchday {match.matchday} -{" "}
                {format(parse(match.time), "dddd Do MMMM HH:mm")}
            </DateHeading>
            <ShootoutPill
                key={match.id}
                {...{ match, onPredictionSubmit, currentPrediction }}
            />
        </Root>
    );
};
