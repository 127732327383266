import { selectUser } from "../reducers";
import { select } from "redux-saga/effects";
import { firebase } from "../../utils/alias";
import merge from "lodash.merge";
import { safeUpdate } from "./firebaseDataSync/safeUpdate";

const postNewUserDetails = function*(user) {
    if (!user.uid) {
        const userDetails = yield select(selectUser);
        const userDetailsCopy = JSON.parse(JSON.stringify(userDetails));
        user = merge(userDetailsCopy, user);
    }
    const ref = firebase.database().ref(`users/${user.uid}`);

    yield safeUpdate(ref, user);
};

export { postNewUserDetails };
