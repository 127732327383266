import React, { Component } from "react";
import { connect } from "react-redux";

class Form extends Component {
    componentWillUnmount() {
        this.props.exitForm();
    }
    render() {
        const { exitForm, ...ownProps } = this.props;
        return <form {...ownProps} />;
    }
}

const dispatchActions = {
    exitForm: () => ({
        type: "EXIT_FORM"
    })
};

Form = connect(
    null,
    dispatchActions
)(Form);

export { Form };
