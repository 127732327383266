import React from "react";
import { Layout } from "../components/layout/Layout";
import { frequenciesForMatch, selectMatch } from "../store/reducers";
import { connect } from "react-redux";
import { Pill } from "../components/predictions/Inputs/Pill";
import { asPercentage } from "../utils/numbers";

let Match = ({ match, frequencies }) => {
    return (
        <Layout>
            <h2>
                {match.homeTeam} vs {match.awayTeam}
            </h2>
            <Pill
                {...match}
                frequencies={frequencies}
                subheadings={
                    frequencies && {
                        home: asPercentage(frequencies.home),
                        draw: asPercentage(frequencies.draw),
                        away: asPercentage(frequencies.away)
                    }
                }
            />
        </Layout>
    );
};

const mapState = (
    state,
    {
        match: {
            params: { id }
        }
    }
) => ({
    match: selectMatch(id)(state),
    frequencies: frequenciesForMatch(id)(state)
});

Match = connect(mapState)(Match);

export { Match };
