import { combineReducers } from "redux";
import { resources } from "./resources";
import { updates } from "./updates";
import { loGet } from "../../utils/alias";
import orderBy from "lodash.orderby";

const user = (state = {}, action) => {
    switch (action.type) {
        case "USER_FOUND":
            return {
                ...state,
                auth: action.user
            };
        case "UPDATE_USER":
        case "GET_CURRENT_USER_RESPONSE":
            if (!action.user) {
                return state;
            }
            const { auth, ...newUserDetails } = action.user;
            return {
                ...state,
                ...newUserDetails
            };
        case "NO_USER_FOUND":
        case "LOGOUT":
            return {};
        default:
            return state;
    }
};

const notifications = (state = {}, action) => {
    switch (action.type) {
        case "ADD_NOTIFICATION":
            return {
                ...state,
                [action.notification.id]: action.notification
            };
        case "REMOVE_NOTIFICATION":
            const { [action.id]: removed, ...others } = state;
            return others;
        default:
            return state;
    }
};

const permissions = (state = {}, action) => {
    switch (action.type) {
        case "GET_PERMISSIONS_RESPONSE":
            return action.permissions ? action.permissions : state;
        default:
            return state;
    }
};

const frequencies = (state = {}, action) => {
    switch (action.type) {
        case "GET_FREQUENCIES_RESPONSE":
            return action.frequencies;
        default:
            return state;
    }
};

const predictions = (state = {}, action) => {
    switch (action.type) {
        case "GET_PREDICTIONS_RESPONSE":
            return action.predictions;
        default:
            return state;
    }
};

const matches = (state = { byId: {}, ids: [] }, action) => {
    switch (action.type) {
        case "GET_MATCHES_RESPONSE":
            return action.matches;
        default:
            return state;
    }
};

const leaderboard = (state = {}, action) => {
    switch (action.type) {
        case "GET_LEADERBOARD_RESPONSE":
            return action.users;
        default:
            return state;
    }
};

const leaderboards = (state = {}, action) => {
    switch (action.type) {
        case "GET_LEADERBOARDS_RESPONSE":
            return action.payload;
        default:
            return state;
    }
};

const orderedLeaderboard = (state = [], action) => {
    switch (action.type) {
        case "GET_LEADERBOARD_RESPONSE":
            return action.userIds;
        default:
            return state;
    }
};

const formError = (state = {}, action) => {
    switch (action.type) {
        case "FORM_FAIL":
            return action.formError;
        case "EXIT_FORM":
            return {};
        default:
            return state;
    }
};

const appReducer = combineReducers({
    formError,
    frequencies,
    matches,
    notifications,
    orderedLeaderboard,
    permissions,
    predictions,
    updates,
    user,
    leaderboard,
    leaderboards,
    resources
});

const rootReducer = (state = {}, action) => {
    if (action.type === "LOGOUT") {
        return appReducer(
            {
                matches: state.matches
            },
            {}
        );
    }
    return appReducer(state, action);
};

const selectUser = state => loGet(state, ["user"]);
const userAuthData = state => loGet(selectUser(state), ["auth"]);
const selectUid = state => loGet(userAuthData(state), "uid");
const isLoggedIn = state => !!selectUid(state);

const email = state => loGet(userAuthData(state), ["email"]);
const displayName = state => loGet(selectUser(state), ["displayName"]);

const userLeaderboards = state => {
    const object = loGet(selectUser(state), ["leaderboards"]);

    return object ? Object.values(object) : null;
};

const formErrorMessage = state => state.formError.message;

const userPredictions = state => state.predictions || {};

const currentUserPredictions = state => {
    const allPredictions = userPredictions(state);
    const currentMatchIds = matchIds(state);
    const currentPredictions = currentMatchIds.reduce(
        (mem, id) => ({
            ...mem,
            [id]: allPredictions[id]
        }),
        {}
    );
    return currentPredictions;
};

const predictionsMade = state => {
    const pred = currentUserPredictions(state);
    return Object.keys(pred).filter(p => pred[p] && pred[p].prediction).length;
};

const resourceLoaded = resourceName => state => !state.resources[resourceName];
const userLoaded = state =>
    resourceLoaded("USER")(state) &&
    (isLoggedIn(state) ? displayName(state) : true);
const permissionsLoaded = resourceLoaded("PERMISSIONS");
const allLoaded = state =>
    userLoaded(state) &&
    permissionsLoaded(state) &&
    resourceLoaded("PREDICTIONS")(state) &&
    resourceLoaded("MATCHES")(state) &&
    resourceLoaded("LEADERBOARDS")(state) &&
    resourceLoaded("LEADERBOARD")(state);

const matchesById = state => state.matches.byId;
const selectMatch = id => state => matchesById(state)[id];
const matchIds = state => state.matches.ids;

const matchIdsForMatchday = matchday => state => {
    const allMatchesById = matchesById(state);
    const matchdayMatches = Object.values(allMatchesById).filter(m => {
        return m.matchday === matchday;
    });
    const orderedMatchdayMatches = orderBy(matchdayMatches, m => m.time);
    return orderedMatchdayMatches.map(m => m.id);
};

const completedMatches = state =>
    matchIds(state)
        .map(id => matchesById(state)[id])
        .filter(match => !!match.result);

export const notPostponedMatchIds = state =>
    matchIds(state).filter(id => matchesById(state)[id].status !== "POSTPONED");

const appNotifications = state => state.notifications;

const isAdmin = state => loGet(state, ["permissions", "admin"]);
const frequenciesForMatch = id => state => loGet(state, ["frequencies", id]);

export {
    allLoaded,
    appNotifications,
    completedMatches,
    currentUserPredictions,
    displayName,
    displayName as selectDisplayName,
    email,
    formErrorMessage,
    frequenciesForMatch,
    isAdmin,
    isLoggedIn,
    isLoggedIn as selectIsLoggedIn,
    matchesById,
    matchIds,
    matchIdsForMatchday,
    permissionsLoaded,
    predictionsMade,
    rootReducer,
    selectUid,
    selectUser,
    selectMatch,
    userLeaderboards,
    userLoaded,
    userPredictions
};
