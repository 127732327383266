import moment from "moment";

const isAfter = (first, second) => moment(first).isAfter(moment(second));
const isBefore = (first, second) => moment(first).isBefore(moment(second));
const format = (date, formatTemplate) => moment(date).format(formatTemplate);
const headingFormatDate = date => moment(date).format("dddd Do MMMM");
const timeFormatDate = date => moment(date).format("HH:mm");
const setHours = (date, hours) => moment(date).hours(hours);
const setMinutes = (date, minutes) => moment(date).minutes(minutes);

export {
    isAfter,
    format,
    setHours,
    setMinutes,
    isBefore,
    headingFormatDate,
    timeFormatDate
};
