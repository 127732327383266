import uniq from "uniqid";
import { put } from "redux-saga/effects";
import { delay } from "redux-saga";

const alertSaved = function*() {
    const id = uniq();
    yield put({
        type: "ADD_NOTIFICATION",
        notification: {
            id,
            message: "Saved!"
        }
    });
    yield delay(800);
    yield put({ type: "REMOVE_NOTIFICATION", id });
};

export { alertSaved };
