import React from "react";
import styled from "styled-components";
import { colours } from "../styles/colours";
const Root = styled.div`
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    color: ${colours.positive[8]};
`;
const GreenLight = styled.div`
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 0.2rem;
    background-color: ${colours.positive[6]};
    margin-left: 0.5rem;
    box-shadow: 0 0 0.1rem 0.1rem ${colours.positive[4]},
        0 0 0.3rem 0.3rem ${colours.positive[2]};

    @keyframes light {
        100% {
            background-color: ${colours.positive[4]};
        }
    }
    animation-name: light;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
`;

export const LiveIndicator = () => (
    <Root>
        live
        <GreenLight />
    </Root>
);
