import React from "react";
import styled from "styled-components";
import { brightBlue } from "./predictions/styles/colours";

const Container = styled.div`
    display: inline-block;
    height: 1rem;
    width: 1rem;
    path {
        fill: ${brightBlue};
    }
`;

const EditIcon = () => (
    <Container>
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 129 129"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            enableBackground="new 0 0 129 129"
        >
            <g>
                <g>
                    <path d="m5.7,78l-.1,19.5c0,1.1 0.4,2.2 1.2,3 0.8,0.8 1.8,1.2 2.9,1.2l19.4-.1c1.1,0 2.1-0.4 2.9-1.2l67-67c1.6-1.6 1.6-4.2 0-5.9l-19.2-19.4c-1.6-1.6-4.2-1.6-5.9-1.77636e-15l-13.4,13.5-53.6,53.5c-0.7,0.8-1.2,1.8-1.2,2.9zm71.2-61.1l13.5,13.5-7.6,7.6-13.5-13.5 7.6-7.6zm-62.9,62.9l49.4-49.4 13.5,13.5-49.4,49.3-13.6,.1 .1-13.5z" />
                </g>
            </g>
        </svg>
    </Container>
);

export { EditIcon };
