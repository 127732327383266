import { takeEvery } from "redux-saga/effects";
import { firebase } from "../../../utils/alias";
import { dispatchOnValueChange } from "./firebaseEvents";

export const leaderboardsRef = () => firebase.database().ref(`leaderboards`);

const getLeaderboardsResponse = leaderboards => {
    return { type: "GET_LEADERBOARDS_RESPONSE", payload: leaderboards };
};

const listenOnLeaderboardsChange = function*() {
    const ref = leaderboardsRef();
    yield dispatchOnValueChange(ref, getLeaderboardsResponse);
};

const watchUserThenListenOnLeaderboards = takeEvery(
    "USER_FOUND",
    listenOnLeaderboardsChange
);

export { watchUserThenListenOnLeaderboards };
